import { useEffect, useState } from "react";
import { types } from "../../texts/types";
import { api_error_translate } from "../../texts/error";
import { interface_translate } from "../../texts/interface";
import useCommon from "../../hooks/useCommon";
import apiRequester from "../../ApiRequester";
import shipmentService from "../../services/shipment";
import styles from "./shered.module.css";

async function get_shipment_cargo_logistics_type_transports() {
  const response = await apiRequester.get("/shipments/cargo_logistics_type_transports");
  return response.data;
}

const BulkCargoLogisticsForm = ({ selectedShipments, onClose, requestGetShipments }) => {
  const { language } = useCommon();
  const [typeTransports, setTypeTransports] = useState([]);
  const [errors, setErrors] = useState({});
  
  // Общие поля для всех отправлений
  const [commonFields, setCommonFields] = useState({
    type_transport: "",
    departure_date: "",
    estimated_date_arrival_in_moscow: "",
    invoice_number: "",
  });

  // Индивидуальные поля для каждого отправления
  const [individualFields, setIndividualFields] = useState(
    selectedShipments.reduce((acc, id) => ({
      ...acc,
      [id]: {
        shipping_cost: "",
        rate: "",
        insurance_cost: "",
        packing_cost: "",
        actual_gross_weight_in_kg: "",
        actual_gross_volume_in_m3: "",
      }
    }), {})
  );

  useEffect(() => {
    const req = async () => {
      const cargoLogisticsTypeTransports = await get_shipment_cargo_logistics_type_transports();
      setTypeTransports(cargoLogisticsTypeTransports);
    };
    req();
  }, []);

  const handleSubmit = async () => {
    try {
      // Подготавливаем данные для каждого отправления
      const shipmentsData = selectedShipments.map(id => ({
        shipment_id: id,
        ...commonFields,
        ...individualFields[id]
      }));

      const success = await shipmentService.enter_bulk_cargo_logistics(
        { shipments: shipmentsData },
        setErrors
      );
      
      if (success) {
        onClose();
      }
    } catch (error) {
      console.error('Error submitting bulk cargo logistics:', error);
    }
  };

  const handleIndividualFieldChange = (shipmentId, field, value) => {
    setIndividualFields(prev => ({
      ...prev,
      [shipmentId]: {
        ...prev[shipmentId],
        [field]: value
      }
    }));
  };

  return (
    <div className="modalWindowDefaultContainer">
      {/* Общие поля */}
      <h3 className={styles.sectionTitle}>{interface_translate['Common fields'][language]}</h3>
      
      <div className={styles.blockAttr}>
        <p>{interface_translate['Type of transport'][language]}</p>
        <select
          value={commonFields.type_transport}
          onChange={(e) => setCommonFields(prev => ({ ...prev, type_transport: e.target.value }))}
        >
          <option value=""></option>
          {typeTransports.map((type, id) => (
            <option key={id} value={type}>
              {types[type][language]}
            </option>
          ))}
        </select>
        {errors['type_transport'] && (
          <div className="openingBlockErrorAttr">
            {api_error_translate[errors['type_transport'][0]][language]}
          </div>
        )}
      </div>

      <div className={styles.blockAttr}>
        <p>{interface_translate['Departure date'][language]}</p>
        <input
          type="date"
          value={commonFields.departure_date}
          onChange={(e) => setCommonFields(prev => ({ ...prev, departure_date: e.target.value }))}
        />
        {errors['departure_date'] && (
          <div className="openingBlockErrorAttr">
            {api_error_translate[errors['departure_date'][0]][language]}
          </div>
        )}
      </div>

      <div className={styles.blockAttr}>
        <p>{interface_translate['Estimated date of arrival in Moscow'][language]}</p>
        <input
          type="date"
          value={commonFields.estimated_date_arrival_in_moscow}
          onChange={(e) => setCommonFields(prev => ({ ...prev, estimated_date_arrival_in_moscow: e.target.value }))}
        />
        {errors['estimated_date_arrival_in_moscow'] && (
          <div className="openingBlockErrorAttr">
            {api_error_translate[errors['estimated_date_arrival_in_moscow'][0]][language]}
          </div>
        )}
      </div>

      <div className={styles.blockAttr}>
        <p>{interface_translate['Invoice number'][language]}</p>
        <input
          type="text"
          value={commonFields.invoice_number}
          onChange={(e) => setCommonFields(prev => ({ ...prev, invoice_number: e.target.value }))}
        />
        {errors['invoice_number'] && (
          <div className="openingBlockErrorAttr">
            {api_error_translate[errors['invoice_number'][0]][language]}
          </div>
        )}
      </div>

      {/* Индивидуальные поля для каждого отправления */}
      <h3 className={styles.sectionTitle}>{interface_translate['Individual fields'][language]}</h3>
      
      {selectedShipments.map(shipmentId => (
        <div key={shipmentId} className={styles.shipmentSection}>
          <h4>ID: {shipmentId}</h4>
          
          <div className={styles.blockAttr}>
            <p>{interface_translate['Total shipping cost'][language]}</p>
            <input
              type="number"
              value={individualFields[shipmentId].shipping_cost}
              onChange={(e) => handleIndividualFieldChange(shipmentId, 'shipping_cost', e.target.value)}
            />
            {errors[`shipments.${shipmentId}.shipping_cost`] && (
              <div className="openingBlockErrorAttr">
                {api_error_translate[errors[`shipments.${shipmentId}.shipping_cost`][0]][language]}
              </div>
            )}
          </div>

          <div className={styles.blockAttr}>
            <p>{interface_translate['Rate'][language]}</p>
            <input
              type="number"
              value={individualFields[shipmentId].rate}
              onChange={(e) => handleIndividualFieldChange(shipmentId, 'rate', e.target.value)}
            />
            {errors[`shipments.${shipmentId}.rate`] && (
              <div className="openingBlockErrorAttr">
                {api_error_translate[errors[`shipments.${shipmentId}.rate`][0]][language]}
              </div>
            )}
          </div>

          <div className={styles.blockAttr}>
            <p>{interface_translate['The cost of insurance'][language]}</p>
            <input
              type="number"
              value={individualFields[shipmentId].insurance_cost}
              onChange={(e) => handleIndividualFieldChange(shipmentId, 'insurance_cost', e.target.value)}
            />
            {errors[`shipments.${shipmentId}.insurance_cost`] && (
              <div className="openingBlockErrorAttr">
                {api_error_translate[errors[`shipments.${shipmentId}.insurance_cost`][0]][language]}
              </div>
            )}
          </div>

          <div className={styles.blockAttr}>
            <p>{interface_translate['The cost of packaging'][language]}</p>
            <input
              type="number"
              value={individualFields[shipmentId].packing_cost}
              onChange={(e) => handleIndividualFieldChange(shipmentId, 'packing_cost', e.target.value)}
            />
            {errors[`shipments.${shipmentId}.packing_cost`] && (
              <div className="openingBlockErrorAttr">
                {api_error_translate[errors[`shipments.${shipmentId}.packing_cost`][0]][language]}
              </div>
            )}
          </div>

          <div className={styles.blockAttr}>
            <p>{interface_translate['Actual total weight in kg'][language]}</p>
            <input
              type="number"
              value={individualFields[shipmentId].actual_gross_weight_in_kg}
              onChange={(e) => handleIndividualFieldChange(shipmentId, 'actual_gross_weight_in_kg', e.target.value)}
            />
            {errors[`shipments.${shipmentId}.actual_gross_weight_in_kg`] && (
              <div className="openingBlockErrorAttr">
                {api_error_translate[errors[`shipments.${shipmentId}.actual_gross_weight_in_kg`][0]][language]}
              </div>
            )}
          </div>

          <div className={styles.blockAttr}>
            <p>{interface_translate['Actual total volume in м³'][language]}</p>
            <input
              type="number"
              value={individualFields[shipmentId].actual_gross_volume_in_m3}
              onChange={(e) => handleIndividualFieldChange(shipmentId, 'actual_gross_volume_in_m3', e.target.value)}
            />
            {errors[`shipments.${shipmentId}.actual_gross_volume_in_m3`] && (
              <div className="openingBlockErrorAttr">
                {api_error_translate[errors[`shipments.${shipmentId}.actual_gross_volume_in_m3`][0]][language]}
              </div>
            )}
          </div>
        </div>
      ))}

      <div className="controlBtns">
        <button onClick={handleSubmit}>
          {interface_translate['Save'][language]}
        </button>
        <button onClick={() => {
          onClose();
          requestGetShipments();
        }}>
          {interface_translate['Exit'][language]}
        </button>
      </div>
    </div>
  );
};

export default BulkCargoLogisticsForm;