import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import usePageMovementHistory from '../../hooks/usePageMovementHistory';
import useCommon from '../../hooks/useCommon';
import ModelView from '../../components/models_view/ModelView.jsx';
import ModelsControls from '../../components/models_view/ModelsControls.jsx';
import ModelFilters from '../../components/ModelFilters.jsx';
import TextFilter from '../../components/filters/TextFilter.jsx';
import SelectFilter from '../../components/filters/SelectFilter.jsx';
import AutocompleteFilter from '../../components/filters/AutocompleteFilter.jsx';
import customerCargoService from '../../services/customer_cargo.js';
import orderService from '../../services/order.js';
import useViewSettings from '../../hooks/useViewSettings.jsx';
import ModalWindow from '../../components/ModalWindow';
import ListAttrMedia from '../../components/ListAttrMedia';
import { statuses_translate } from '../../texts/statuses.js';
import { api_error_translate } from '../../texts/error';
import { interface_translate } from '../../texts/interface.js';
import '../../App.css';
import roleAccess from '../../roleAccess.js';
import { admin, moscow_storekeeper_manager } from '../../roles';
import shipmentService from '../../services/shipment.js';
import userService from '../../services/user.js';
import counterpartyService from '../../services/counterparty.js';
import styles from './CustomerCargoListPage.module.css';

async function get_customer_cargo_list_by_filters(
  paginationSettings,
  filterData
) {
  const customerCargoPaginated = await customerCargoService.get_by_filters(
    filterData,
    paginationSettings
  );
  return customerCargoPaginated.data;
}

async function delete_customer_cargo(id, setGlobalError, language) {
  const result = await customerCargoService.delete(
    id,
    setGlobalError,
    language
  );
  return result;
}

async function add_information_to_customer_cargo(id, data, setErrors) {
  const result = await customerCargoService.add_information(
    id,
    data,
    setErrors
  );
  return result;
}

async function get_shipments(filters = {}, size = 50) {
  const result = await shipmentService.get_by_filters(filters, { size: size });
  return result.data;
}

async function get_orders(filters = {}, size = 50) {
  const result = await orderService.get_by_filters(filters, { size: size });
  return result.data;
}

async function get_order(id) {
  const result = await orderService.get(id);
  return result;
}

async function get_clients(filters = {}, size = 50) {
  const def_filters = { group: 'client', ...filters };
  const result = await counterpartyService.get_by_filters(def_filters, {
    size: size,
  });
  return result.data;
}

async function get_users(filters = {}, size = 50) {
  const result = await userService.get_by_filters(filters, { size: size });
  return result.data;
}

async function get_user(id) {
  const result = await userService.get(id);
  return result;
}

async function get_shipment_logistics(id) {
  const result = await shipmentService.get_logistics(id);
  return result;
}

async function get_customer_cargo_statuses() {
  const result = await customerCargoService.get_statuses();
  return result;
}

const table_all_attrs = [
  {
    name: 'id',
    name_for_display: 'customer cargo id',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'datetime_created',
    name_for_display: 'datetime created',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'client_name',
    name_for_display: 'client',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'content',
    name_for_display: 'content',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'status_translate',
    name_for_display: 'status',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'invoice_number',
    name_for_display: 'cargo number',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'responsible_names',
    name_for_display: 'responsible',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'add_information',
    name_for_display: 'add information',
    translate_name_for_display: null,
    use_sort: true,
  },
];

const CustomerCargoListPage = (props) => {
  const {
    setGlobalError,
    resetGlobalError,
    setGlobalMsg,
    resetGlobalMsg,
    language,
    userRole,
  } = useCommon();
  const { pushPage, clearHistoryPages } = usePageMovementHistory();
  const { allViewSettings, setAllViewSettings } = useViewSettings();
  const viewSettings = allViewSettings['customer_cargo'];
  const navigate = useNavigate();
  const location = useLocation();
  const [filterData, setFiltersData] = useState(
    viewSettings['filterData'] || {}
  );
  const [showFilters, setShowFilters] = useState(false);
  const [paginationSettings, setPaginationSettings] = useState({
    page: 1,
    size: viewSettings['size'],
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [errors, setErrors] = useState({});
  const [customer_cargo_list, setCustomerCargoList] = useState([]);
  const [customerCargoStatuses, setCustomerCargoStatuses] = useState([]);
  const [selectedModelIds, setSelectedModelIds] = useState([]);
  const [sortData, setSortData] = useState({});
  const [customerCargoForAddInformation, setCustomerCargoForAddInformation] =
    useState();
  const [viewAddInformationModal, setViewAddInformationModal] = useState(false);
  const [addInformationData, setAddInformationData] = useState({
    productsQty: {},
  });
  const [addInformationMediaContent, setAddInformationMediaContent] = useState(
    {}
  );
  const [viewEnterInvoiceNumberModal, setViewEnterInvoiceNumberModal] =
    useState(false);
  const [invoiceNumberInput, setInvoiceNumberInput] = useState('');
  const [tableAttrs, setTableAttrs] = useState([]);
  
  useEffect(() => {
    const req = async () => {
      const customerCargoStatuses = await get_customer_cargo_statuses();
      setCustomerCargoStatuses(customerCargoStatuses);
    };
    req();
    clearHistoryPages();
    pushPage(location.pathname);
    const table_attrs = table_all_attrs
      .filter((attr_info) =>
        roleAccess[userRole].fieldRestrictions[
          'Customer cargo list'
        ].read_all.includes(attr_info.name)
      )
      .map((attr_info) => ({
        ...attr_info,
        translate_name_for_display:
          interface_translate[attr_info.name_for_display][language],
      }));
    setTableAttrs(table_attrs);
  }, []);

  useEffect(() => {
    for (let customer_cargo of customer_cargo_list) {
      customer_cargo.status_translate =
        statuses_translate[customer_cargo.status][language];
      if (
        [admin, moscow_storekeeper_manager].includes(userRole) &&
        customer_cargo.status == 'entered_in_storehouse'
      ) {
        customer_cargo.add_information = (
          <div className={styles.btnAddInformation}>
            <button
              onClick={(e) => {
                e.stopPropagation();
                setCustomerCargoForAddInformation(customer_cargo);
                viewAddInformation(customer_cargo);
              }}
            >
              {interface_translate['Add'][language]}
            </button>
          </div>
        );
      }
    }

    setCustomerCargoList([...customer_cargo_list]);
  }, [language]);

  useEffect(() => {
    setAllViewSettings({
      customer_cargo: {
        size: paginationSettings['size'],
        filterData: filterData,
      },
    });
  }, [paginationSettings, filterData]);

  useEffect(() => {
    if (sortData.by === 'status_translate') {
      sortData.by = 'status';
    }
    const newPaginationSettings = {
      ...paginationSettings,
      sort_by: sortData.by,
      sort_order: sortData.order,
    };
    setPaginationSettings(newPaginationSettings);
  }, [sortData]);

  const mapFilterData = () => {
    const data = { ...filterData };
    if (filterData.order) data['order_id'] = filterData.order.id;
    if (filterData.client) data['client_id'] = filterData.client.id;
    if (filterData.shipment) data['shipment_id'] = filterData.shipment.id;
    if (!filterData.status || filterData.status === '') {
      delete data['status'];
    }
    if (filterData.weight_in_kg === '') delete data['weight_in_kg'];
    if (filterData.volume_in_m3 === '') delete data['volume_in_m3'];
    if (filterData.content === '') delete data['content'];
    if (filterData.responsible)
      data['responsible_id'] = filterData.responsible.id;
    if (filterData.co_executors)
      data['co_executor_id'] = filterData.co_executors.id;
    delete data['order'];
    delete data['client'];
    delete data['shipment'];
    delete data['responsible'];
    delete data['co_executors'];
    return data;
  };

  const requestGetCustomerCargoList = async () => {
    let new_customer_cargo_list = [];
    const filter = mapFilterData();
    new_customer_cargo_list = await get_customer_cargo_list_by_filters(
      paginationSettings,
      filter
    );
    for (let customer_cargo of new_customer_cargo_list) {
      customer_cargo.datetime_created = new Date(customer_cargo.datetime_created).toLocaleDateString();
      const order = await get_order(customer_cargo.order_id);
      customer_cargo.client_name = order.client.name;
      let responsible_names = [];
      for (let user_id of customer_cargo.responsible_ids) {
        const user = await get_user(user_id);
        responsible_names.push(`${user.name} ${user.surname}`);
      }
      const uniqueResponsibleNames = new Set(responsible_names);
      const responsibleNamesString = Array.from(uniqueResponsibleNames).map(
        (str, index) => (
          <span key={index}>
            {str}
            <br />
          </span>
        )
      );
      customer_cargo.responsible_names = responsibleNamesString;
      const logistics = await get_shipment_logistics(
        customer_cargo.shipment_id
      );
      customer_cargo.invoice_number = logistics?.invoice_number;
      customer_cargo.status_translate =
        statuses_translate[customer_cargo.status][language];
      if (
        [admin, moscow_storekeeper_manager].includes(userRole) &
        (customer_cargo.status == 'entered_in_storehouse')
      ) {
        customer_cargo.add_information = (
          <div className={styles.btnAddInformation}>
            <button
              onClick={(e) => {
                e.stopPropagation();
                setCustomerCargoForAddInformation(customer_cargo);
                viewAddInformation(customer_cargo);
              }}
            >
              {interface_translate['Add'][language]}
            </button>
          </div>
        );
      }
      customer_cargo.storehouse_name = customer_cargo.storage_cell
        ? customer_cargo.storage_cell.shelving.storehouse.name
        : '';
      customer_cargo.storage_cell_name = customer_cargo.storage_cell
        ? `${customer_cargo.storage_cell.shelving.name}${customer_cargo.storage_cell.number}`
        : '';
    }
    if (!new_customer_cargo_list) return;

    setCustomerCargoList(new_customer_cargo_list);
    setCurrentPage(paginationSettings['page']);
  };

  const addFiltersData = (attr, value) => {
    if (value) {
      setFiltersData({ ...filterData, [attr]: value });
    } else if (filterData.hasOwnProperty(attr)) {
      delete filterData[attr];
      setFiltersData({ ...filterData });
    }
  };

  const toggleShowFilters = () => {
    setShowFilters(!showFilters);
  };

  const handleSetPaginationSettings = (settings) => {
    setPaginationSettings({ ...paginationSettings, ...settings });
  };

  useEffect(() => {
    requestGetCustomerCargoList();
  }, [paginationSettings]);

  const deleteModels = async () => {
    setCustomerCargoList(
      customer_cargo_list.filter(
        (model) => !selectedModelIds.includes(model.id)
      )
    );
    for (const id of selectedModelIds) await delete_customer_cargo(id);
  };

  const viewAddInformation = (customer_cargo) => {
    setViewAddInformationModal(true);
    const productsQty = {};
    for (const customer_cargo_product of customer_cargo.customer_cargo_products) {
      productsQty[customer_cargo_product.id] = {
        qty: customer_cargo_product.qty,
        was_qty_check: false,
      };
    }
    setAddInformationData({ ...addInformationData, productsQty });
  };

  const getDataForAddInformaion = () => {
    const customer_cargo_products_qty = Object.entries(
      addInformationData.productsQty
    ).map(([key, value]) => {
      return {
        customer_cargo_product_id: key,
        qty: value.qty,
        was_qty_check: value.was_qty_check,
      };
    });
    const data = {
      weight_in_kg: addInformationData.weight_in_kg || null,
      volume_in_m3: addInformationData.volume_in_m3 || null,
      content: addInformationData.content || '',
      customer_cargo_products_qty: customer_cargo_products_qty,
      media_content: [],
    };
  
    
    Object.values(addInformationMediaContent).forEach((file) => {
      if (file instanceof File || file instanceof Blob) {
        data.media_content.push(file);
      } else if (Array.isArray(file)) {
        file.forEach(f => {
          if (f instanceof File || f instanceof Blob) {
            data.media_content.push(f);
          } else {
            console.error('Invalid file type in array:', typeof f);
          }
        });
      } else {
        console.error('Invalid file type:', typeof file);
      }
    });
  
    return data;
  };

  return (
    <div>
      <ModelsControls
        section="Customer cargo list"
        model_name={interface_translate['Customer cargo list'][language]}
        filtersActivated={Object.keys(filterData).length > 0}
        toggleShowFilters={toggleShowFilters}
        requestModels={requestGetCustomerCargoList}
        deleteModels={deleteModels}
      >
        {roleAccess[userRole].permissions['Customer cargo list']
          .enter_invoice_nubmer && (
          <button
            onClick={() => {
              setViewEnterInvoiceNumberModal(true);
            }}
          >
            {interface_translate['Enter the invoce number'][language]}
          </button>
        )}
      </ModelsControls>
      {showFilters && (
        <ModelFilters
          requestModels={requestGetCustomerCargoList}
          resetFilterData={() => setFiltersData({})}
        >
          <AutocompleteFilter
            title_name={interface_translate['Order'][language]}
            attr="order"
            addFiltersData={addFiltersData}
            defaultValue={
              filterData['order'] != undefined ? filterData['order'] : null
            }
            getElementLabel={(option) => option.id.toString()}
            searchElements={async (value) => {
              let filters = {};
              if (value) filters['order'] = value;
              const orders = await get_orders(filters);
              return orders;
            }}
          />
          <AutocompleteFilter
            title_name={interface_translate['Client'][language]}
            attr="client"
            addFiltersData={addFiltersData}
            defaultValue={
              filterData['client'] != undefined ? filterData['client'] : null
            }
            getElementLabel={(option) => option.name}
            searchElements={async (value) => {
              let filters = {};
              if (value) filters['name'] = value;
              const client = await get_clients(filters);
              return client;
            }}
          />
          <AutocompleteFilter
            title_name={interface_translate['Shipment'][language]}
            attr="shipment"
            addFiltersData={addFiltersData}
            defaultValue={
              filterData['shipment'] != undefined
                ? filterData['shipment']
                : null
            }
            getElementLabel={(option) => option.id.toString()}
            searchElements={async (value) => {
              let filters = {};
              if (value) filters['shipment'] = value;
              const shipments = await get_shipments(filters);
              return shipments;
            }}
          />
          <TextFilter
            title_name={interface_translate['Content'][language]}
            attr="content"
            addFiltersData={addFiltersData}
            defaultValue={
              filterData['content'] != undefined ? filterData['content'] : ''
            }
          />
          <SelectFilter
            title_name={interface_translate['Status'][language]}
            attr="status"
            select_names={[
              '',
              ...customerCargoStatuses.map(
                (m) => statuses_translate[m][language]
              ),
            ]}
            select_attr={['', ...customerCargoStatuses]}
            addFiltersData={addFiltersData}
            defaultValue={
              filterData['status'] != undefined ? filterData['status'] : ''
            }
          />
          <TextFilter
            title_name={interface_translate['Weight in kg'][language]}
            attr="weight_in_kg"
            addFiltersData={addFiltersData}
            defaultValue={
              filterData['weight_in_kg'] != undefined
                ? filterData['weight_in_kg']
                : ''
            }
          />
          <TextFilter
            title_name={interface_translate['Volume in м³'][language]}
            attr="volume_in_m3"
            addFiltersData={addFiltersData}
            defaultValue={
              filterData['volume_in_m3'] != undefined
                ? filterData['volume_in_m3']
                : ''
            }
          />
          <AutocompleteFilter
            title_name={interface_translate['Responsible'][language]}
            attr="responsible"
            addFiltersData={addFiltersData}
            defaultValue={
              filterData['responsible'] != undefined
                ? filterData['responsible']
                : null
            }
            getElementLabel={(option) => option.email}
            searchElements={async (value) => {
              let filters = {};
              if (value) filters['email'] = value;
              const responsible = await get_users(filters);
              return responsible;
            }}
          />
          <AutocompleteFilter
            title_name={interface_translate['Co executors'][language]}
            attr="co_executors"
            addFiltersData={addFiltersData}
            defaultValue={
              filterData['co_executors'] != undefined
                ? filterData['co_executors']
                : null
            }
            getElementLabel={(option) => option.email}
            searchElements={async (value) => {
              let filters = {};
              if (value) filters['email'] = value;
              const coExecutors = await get_users(filters);
              return coExecutors;
            }}
          />
        </ModelFilters>
      )}
      {viewAddInformationModal && (
        <ModalWindow
          isActive={viewAddInformationModal}
          setIsActive={(v) => {
            setAddInformationData({ productsQty: {} });
            setViewAddInformationModal(v);
          }}
        >
          <div className={styles.AddInformationContainer}>
            <div className={styles.controlBtns}>
              <button
                onClick={async () => {
                  const data = getDataForAddInformaion();
                  if (!data) return;
                  const result = await add_information_to_customer_cargo(
                    customerCargoForAddInformation.id,
                    data,
                    setErrors,
                    resetGlobalMsg
                  );
                  if (result) {
                    setGlobalMsg('Данные добавлены');
                    resetGlobalError();
                    setErrors({});
                    requestGetCustomerCargoList();
                    setViewAddInformationModal(false);
                  }
                  setAddInformationData((prevData) => {
                    const newData = { productsQty: prevData.productsQty };
                    return newData;
                  });
                }}
              >
                Сохранить
              </button>
              <button>Выйти</button>
            </div>
            <div className="openingBlockAttr">
              <p className="openingBlockAttrName">Общий вес</p>
              <input
                type="text"
                value={addInformationData.weight_in_kg}
                onChange={(e) => {
                  setAddInformationData({
                    ...addInformationData,
                    weight_in_kg: e.target.value,
                  });
                }}
              />
            </div>
            {errors['weight_in_kg'] && (
              <div className="openingBlockErrorAttr">
                {api_error_translate[errors['weight_in_kg'][0].split(":")[0]][language]}
              </div>
            )}
            <div className="openingBlockAttr">
              <p className="openingBlockAttrName">Общий объем</p>
              <input
                type="text"
                value={addInformationData.volume_in_m3}
                onChange={(e) => {
                  setAddInformationData({
                    ...addInformationData,
                    volume_in_m3: e.target.value,
                  });
                }}
              />
            </div>
            {errors['volume_in_m3'] && (
              <div className="openingBlockErrorAttr">
                {api_error_translate[errors['volume_in_m3'][0].split(":")[0]][language]}
              </div>
            )}
            <div className="openingBlockAttr">
              <p className="openingBlockAttrName">Фактическое содержимое</p>
              <input
                type="text"
                value={addInformationData.content}
                onChange={(e) => {
                  setAddInformationData({
                    ...addInformationData,
                    content: e.target.value,
                  });
                }}
              />
            </div>
            {errors['content'] && (
              <div className="openingBlockErrorAttr">
                {api_error_translate[errors['content'][0]][language]}
              </div>
            )}
            <div className="openingBlockAttr">
              <p className="openingBlockAttrName">
                {interface_translate['Media content'][language]}
                <br />
                {`${interface_translate['Allowed formats'][language]}: "jpg", "png", "webp"`}
                {`"heic", "mp4", "mov", "pdf", "docx", "xlsx"`}
              </p>
              <ListAttrMedia
                setSelectedElement={(value) => {
                  setAddInformationMediaContent((prevData) => {
                    return { ...prevData, ...value };
                  });
                }}
                deleteSelectedElement={(id) => {
                  setAddInformationMediaContent((prevElements) => {
                    delete prevElements[id];
                    return { ...prevElements };
                  });
                }}
              />
            </div>
            {errors['media_content'] && (
              <div className="openingBlockErrorAttr">
                {api_error_translate[errors['media_content'][0]][language]}
              </div>
            )}
            <div className="vertical align-start">
              {customerCargoForAddInformation.customer_cargo_products.map(
                (customer_cargo_product, id) => {
                  return (
                    <div
                      key={id}
                      className={`horizontal openingBlockAttr`}
                    >
                      <div className="openingBlockAttrName">{`Товар(id: ${customer_cargo_product.product.id}) ${customer_cargo_product.product.name}`}</div>
                      <input
                        value={
                          addInformationData.productsQty[
                            customer_cargo_product.id
                          ].qty
                        }
                        onChange={(e) => {
                          const productQty =
                            addInformationData['productsQty'][
                              customer_cargo_product.id
                            ];
                          productQty.qty = e.target.value;
                          setAddInformationData({
                            ...addInformationData,
                            productsQty: {
                              ...addInformationData['productsQty'],
                              [customer_cargo_product.id]: productQty,
                            },
                          });
                        }}
                      />
                      <div className="horizontal">
                        <input
                          type="checkbox"
                          value="was_qty_check"
                          onChange={(e) => {
                            const productQty =
                              addInformationData['productsQty'][
                                customer_cargo_product.id
                              ];
                            productQty.was_qty_check = e.target.checked;
                            setAddInformationData({
                              ...addInformationData,
                              productsQty: {
                                ...addInformationData['productsQty'],
                                [customer_cargo_product.id]: productQty,
                              },
                            });
                          }}
                        />
                        <p className="">
                          {interface_translate['Was check qty'][language]}
                        </p>
                      </div>
                    </div>
                  );
                }
              )}
            </div>
          </div>
        </ModalWindow>
      )}
      <ModelView
        tableAttrs={tableAttrs}
        models={customer_cargo_list}
        setSelectedModels={setSelectedModelIds}
        sortData={sortData}
        setSortData={setSortData}
        setPaginationSettings={handleSetPaginationSettings}
        currentPage={currentPage}
        currentSize={paginationSettings['size']}
      />
      {viewEnterInvoiceNumberModal && (
        <ModalWindow
          isActive={viewEnterInvoiceNumberModal}
          setIsActive={(value) => {
            setViewEnterInvoiceNumberModal(value);
          }}
        >
          <div className="modalWindowDefaultContainer">
            <div className="modalWindowDefaultFieldInput">
              <span>{interface_translate['Invoice number'][language]}</span>
              <input
                type="text"
                value={invoiceNumberInput}
                onChange={(e) => setInvoiceNumberInput(e.target.value)}
              />
            </div>
            <button
              onClick={async () => {
                navigate(
                  `/shipments/by_invoice_number?invoice_number=${invoiceNumberInput}`,
                  {
                    relative: 'path',
                  }
                );
                setViewEnterInvoiceNumberModal(false);
              }}
            >
              {interface_translate['Encontrar'][language]}
            </button>
          </div>
        </ModalWindow>
      )}
    </div>
  );
};

export default CustomerCargoListPage;
