import apiRequester from '../ApiRequester';
import { model_names } from '../texts/model_names.js';
import { api_error_translate } from '../texts/error.js';

class UserService {
  async login(data, setGlobalError) {
    const jsonData = JSON.stringify(data);
    try {
      const result = await apiRequester.post('/users/login', jsonData);
      return result.data;
    } catch (err) {
      if (err.response.status === 400) {
        const detail = err.response.data.detail;
        setGlobalError(api_error_translate[detail]['ru']);
        return;
      } else {
        console.error(`Could not fetch data: ${err}`);
      }
    }
  }

  async get(id) {
    try {
      const response = await apiRequester.get(`/users/${id}`);
      const user = response.data;
      return user;
    } catch (err) {
      if (err.response.status !== 200) {
        throw new Error(`HTTP error: ${err.response.status}`);
      }
      console.error(`Could not fetch data: ${err}`);
    }
  }

  async get_by_filters(filterData, paginationSettings = { size: 20 }) {
    const jsonData = JSON.stringify(filterData);
    try {
      const response = await apiRequester.post('/users/filters', jsonData, {
        params: paginationSettings,
      });
      const users = response.data;
      return users;
    } catch (err) {
      console.error(`Could not fetch data: ${err}`);
      throw err;
    }
  }

  async addEmployee(data) {
    const jsonData = JSON.stringify({
        email: data.email,
        raw_password: data.raw_password,
        full_name: data.full_name,
        phone_number: data.phone_number,
        link_to_messenger: data.link_to_messenger,
        role: data.role,
        bitrix_id: data.bitrix_id,
    });

    try {
        const result = await apiRequester.post('/users/register_employee', jsonData);
        return result.data;
    } catch (err) {
        if (err.response && err.response.status === 400) {
            return err.response.data;
        } else {
            console.error(`Could not register employee: ${err.response ? err.response.data : err}`);
            throw err;
        }
    }
  }
  async getAll(filters = {}) {
    try {
      const paginationParams = {
        page: filters.page || 1,
        size: filters.size || 50,
        sort_by: filters.sort_by || null,
        sort_order: filters.sort_order || "DESC"
      };
  
      const response = await apiRequester.get("", { 
        params: paginationParams 
      });
      
      // Проверяем структуру ответа
      if (response.data && response.data.data) {
        return response.data.data; // Возвращаем массив пользователей из структуры пагинации
      }
      return [];
    } catch (err) {
      console.error("Failed to fetch users:", err);
      return [];
    }
  }
  
  async update(id, data) {
    try {
      // Убираем undefined и null значения и преобразуем в формат UserUpdate
      const cleanData = {
        id: parseInt(id), // Убеждаемся, что id - число
        email: data.email || undefined,
        name: data.name || undefined,
        surname: data.surname || undefined,
        patronymic: data.patronymic || undefined,
        phone_number: data.phone_number || undefined,
        link_to_messenger: data.link_to_messenger || undefined,
        bitrix_id: data.bitrix_id || undefined,
        raw_password: data.raw_password || undefined,
        role: data.role || undefined
      };
  
      // Удаляем все undefined поля
      Object.keys(cleanData).forEach(key => 
        cleanData[key] === undefined && delete cleanData[key]
      );
      
      const response = await apiRequester.put(`/users/${id}`, cleanData);
      return response.data;
    } catch (err) {
      console.error("Failed to update user:", err.message);
      throw err;
    }
  }
}

const userService = new UserService();
export default userService;
