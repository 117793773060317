import { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import usePageMovementHistory from '../../hooks/usePageMovementHistory';
import useCommon from '../../hooks/useCommon';
import OpeningBlock from '../../components/OpeningBlock';
import DropDownMediaBlock from '../../components/DropDownMediaBlock';
import ConfirmDialog from '../..//components/ConfirmDialog.jsx';
import ModalWindow from '../../components/ModalWindow';
import ModelView from '../../components/models_view/ModelView.jsx';
import userService from '../../services/user.js';
import customerCargoService from '../../services/customer_cargo.js';
import styles from './CustomerCargoPage.module.css';
import SelectStorageCellBtn from '../../components/SelectStorageCellBtn.jsx';
import { statuses_translate } from '../../texts/statuses.js';
import { interface_translate } from '../../texts/interface';
import { api_error_translate } from '../../texts/error.js';
import { types } from '../../texts/types.js';
import roleAccess from '../../roleAccess.js';
import orderService from '../../services/order.js';
import shipmentService from '../../services/shipment.js';
import '../shared.css';
import { getPrevViewImgAndMediaBlockByImageObj } from '../../utils/image.js';
import ShipmentRead from '../../components/model_cards/ShipmentRead.jsx';

async function get_customer_cargo(id) {
  const result = await customerCargoService.get(id);
  return result;
}

async function get_shipment(id) {
  const result = await shipmentService.get(id);
  return result;
}

async function get_shipment_logistics(id) {
  const result = await shipmentService.get_logistics(id);
  return result;
}

async function get_user(id) {
  const result = await userService.get(id);
  return result;
}

async function send_req_transfer_to_client(id) {
  const customer_cargo = await customerCargoService.transfer_to_client(id);
  return customer_cargo;
}

async function send_req_enter_information_about_signing_documents(
  id, 
  data, 
  setErrors
) {
  try {
    const result = await customerCargoService.enter_information_about_signing_documents(
      id,
      data
    );
    return result;
  } catch (error) {
    if (error.errors) {
      setErrors(error.errors);
    }
    throw error;
  }
}

async function set_status_ready_to_issue(
  id,
  setErrors,
  setGlobalError,
  resetGlobalMsg
) {
  const data = { status: 'ready_to_issue' };
  const result = await customerCargoService.update(
    id,
    data,
    setErrors,
    setGlobalError,
    resetGlobalMsg
  );
  return result;
}

async function update_cusomer_cargo_storage_cell(
  id,
  storage_cell_id,
  setErrors,
  setGlobalError,
  resetGlobalMsg
) {
  const data = { storage_cell_id: storage_cell_id };
  const result = await customerCargoService.update(
    id,
    data,
    setErrors,
    setGlobalError,
    resetGlobalMsg
  );
  return result;
}

async function get_order(id) {
  const result = await orderService.get(id);
  return result;
}

function get_product_for_display(customer_cargo_product, order, language) {
  const product = customer_cargo_product.product;
  if (!product.link) product.link = '';
  product.status_translate = statuses_translate[product.status][language];
  product.qty_with_unit = `${product.qty} ${
    types[product.measurement_unit][language]
  }`;
  product.total_cost = product.price * product.qty;
  product.supplier_name = product.supplier.name;
  product.client_name = order.client.name;

  customer_cargo_product.customer_cargo_qty_with_unit = `${
    customer_cargo_product.qty
  } ${types[customer_cargo_product.product.measurement_unit][language]}`;
  return { ...product, ...customer_cargo_product };
}

const table_all_attrs = [
  {
    name: 'id',
    name_for_display: 'product id',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'client_name',
    name_for_display: 'client',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'order_id',
    name_for_display: 'order',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'name',
    name_for_display: 'name',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'status_translate',
    name_for_display: 'status',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'customer_cargo_qty_with_unit',
    name_for_display: 'qty',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'price',
    name_for_display: 'price',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'currency',
    name_for_display: 'currency',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'supplier_name',
    name_for_display: 'supplier',
    translate_name_for_display: null,
    use_sort: true,
  },
];

const CustomerCargoPage = (props) => {
  const {
    language,
    setGlobalMsg,
    setGlobalError,
    resetGlobalMsg,
    resetGlobalError,
    userRole,
  } = useCommon();
  const { handleExit } = props;
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { pushPage, popPage, getCurrentPage } = usePageMovementHistory();
  const [customer_cargo, setCustomerCargo] = useState();
  const [shipment, setShipment] = useState();
  const [shipmentLogistics, setShipmentLogistics] = useState();
  const [errors, setErrors] = useState({});
  const [viewTransferToClientConfirm, setViewTransferToClientConfirm] =
    useState();
  const [viewSubmitClosingDocumentsModal, setViewSubmitClosingDocumentsModal] =
    useState(false);
  const [submitClosingDocumentsData, setSubmitClosingDocumentsData] = useState(
    {}
  );
  const [viewReadyToIssue, setViewReadyToIssue] = useState(false);
  const [selectStorageCellErrors, setSelectStorageCellErrors] = useState({});
  const [attrsForRead, setAttrsForRead] = useState(new Set());
  const [shipmentAttrsForRead, setShipmentAttrsForRead] = useState([]);
  const [productTableAttrs, setProductTableAttrs] = useState([]);

  useEffect(() => {
    const req = async () => {
      await requestGetCustomerCargo();
    };
    req();
    pushPage(location.pathname);
    const attrs_for_read = new Set(
      roleAccess[userRole].fieldRestrictions['Customer cargo list']?.read
    );
    setAttrsForRead(attrs_for_read);
    pushPage(location.pathname);
    const shipment_attrs_for_read = new Set(
      roleAccess[userRole].fieldRestrictions.Shipments?.read
    );
    setShipmentAttrsForRead(shipment_attrs_for_read);
    const productTableAttrs = table_all_attrs
      .filter((attr_info) =>
        roleAccess[userRole].fieldRestrictions.Products.read_all.includes(
          attr_info.name
        )
      )
      .map((attr_info) => ({
        ...attr_info,
        translate_name_for_display:
          interface_translate[attr_info.name_for_display][language],
      }));
    setProductTableAttrs(productTableAttrs);
  }, []);

  useEffect(() => {
    if (!customer_cargo) return;
    customer_cargo.status_tranclate =
      statuses_translate[customer_cargo.status][language];
    setCustomerCargo({ ...customer_cargo });
  }, [language]);

  const updateStorageCell = async (storage_cell) => {
    if (!storage_cell) return;
    const result = await update_cusomer_cargo_storage_cell(
      customer_cargo.id,
      storage_cell.id,
      setSelectStorageCellErrors,
      setGlobalError,
      resetGlobalMsg
    );
    if (result) {
      requestGetCustomerCargo();
    }
    return result;
  };

  const requestGetCustomerCargo = async () => {
    const customer_cargo_resp = await get_customer_cargo(params.id);
    let order = await get_order(customer_cargo_resp.order_id);
    customer_cargo_resp.order = order;
    const responsible = [];
    for (const user_id of customer_cargo_resp.responsible_ids) {
      const user = await get_user(user_id);
      responsible.push(user);
    }
    customer_cargo_resp.responsible = responsible;
    const co_executors = [];
    for (const user_id of customer_cargo_resp.co_executors_ids) {
      const user = await get_user(user_id);
      co_executors.push(user);
    }
    customer_cargo_resp.co_executors = co_executors;
    customer_cargo_resp.status_tranclate =
      statuses_translate[customer_cargo_resp.status][language];
    customer_cargo_resp.storehouse_id = customer_cargo_resp.storage_cell
      ? customer_cargo_resp.storage_cell.shelving.storehouse.id
      : '';
    customer_cargo_resp.storehouse_name = customer_cargo_resp.storage_cell
      ? customer_cargo_resp.storage_cell.shelving.storehouse.name
      : '';
    customer_cargo_resp.storage_cell_name = customer_cargo_resp.storage_cell
      ? `${customer_cargo_resp.storage_cell.shelving.name}${customer_cargo_resp.storage_cell.number}`
      : '';
    let total_amount = 0;
    const customer_cargo_products = [];
    for (let customer_cargo_product of customer_cargo_resp.customer_cargo_products) {
      customer_cargo_product = get_product_for_display(
        customer_cargo_product,
        order,
        language
      );
      if (customer_cargo_product.currency == 'cny') {
        total_amount +=
          customer_cargo_product.total_cost * order.rate_cny_to_rub;
      } else if (customer_cargo_product.currency == 'usd') {
        total_amount +=
          customer_cargo_product.total_cost * order.rate_dollar_to_rub;
      } else if (customer_cargo_product.currency == 'rub') {
        total_amount += customer_cargo_product.total_cost;
      }
      customer_cargo_products.push(customer_cargo_product);
    }
    customer_cargo_resp.customer_cargo_products = customer_cargo_products;
    customer_cargo_resp.total_amount = total_amount;

    const shipment = await get_shipment(customer_cargo_resp.shipment_id);
    const shipmentonsible = [];
    for (const user_id of shipment.responsible_ids) {
      const user = await get_user(user_id);
      shipmentonsible.push(user);
    }
    shipment.responsible = shipmentonsible;
    const shipment_co_executors = [];
    for (const user_id of shipment.co_executors_ids) {
      const user = await get_user(user_id);
      shipment_co_executors.push(user);
    }
    shipment.co_executors = shipment_co_executors;
    const shipment_logistics = await get_shipment_logistics(shipment.id);
    shipment.status_tranclate = statuses_translate[shipment.status][language];
    shipment.packing_translate = types[shipment.packing][language];
    shipment.storehouse_id = shipment.storage_cell
      ? shipment.storage_cell.shelving.storehouse.id
      : '';
    shipment.storehouse_name = shipment.storage_cell
      ? shipment.storage_cell.shelving.storehouse.name
      : '';
    shipment.storage_cell_name = shipment.storage_cell
      ? `${shipment.storage_cell.shelving.name}${shipment.storage_cell.number}`
      : '';
    shipment.logistics = shipment_logistics;
    customer_cargo_resp.shipment = shipment;
    setShipment(shipment);
    setShipmentLogistics(shipment_logistics);

    setCustomerCargo(customer_cargo_resp);
  };

  const getDataForSubmitClosingDocuments = () => {
    const data = {
      ...submitClosingDocumentsData,
    };
    return data;
  };

  if (customer_cargo == null) return;
  return (
    <div className="pageViewModel">
      {viewReadyToIssue && (
        <div className={styles}>
          <ConfirmDialog
            message="Подтвердить действие?"
            confirmMsg="Да"
            cancelMsg="Нет"
            onConfirm={async () => {
              await set_status_ready_to_issue(
                customer_cargo.id,
                setErrors,
                setGlobalError,
                resetGlobalMsg
              );
              requestGetCustomerCargo();
              setViewReadyToIssue(false);
            }}
            onCancel={() => setViewTransferToClientConfirm(false)}
          />
        </div>
      )}
      {viewTransferToClientConfirm && (
        <div className={styles}>
          <ConfirmDialog
            message="Посылка выдана клиенту?"
            confirmMsg="Да"
            cancelMsg="Нет"
            onConfirm={async () => {
              await send_req_transfer_to_client(customer_cargo.id);
              requestGetCustomerCargo();
              setViewTransferToClientConfirm(false);
            }}
            onCancel={() => setViewTransferToClientConfirm(false)}
          />
        </div>
      )}
      <div className="controls">
        {roleAccess[userRole].permissions['Customer cargo list'].edit && (
          <button
            onClick={() => {
              navigate('edit');
            }}
          >
            {interface_translate['Edit'][language]}
          </button>
        )}
        <button
          onClick={() => {
            popPage();
            const previousPage = getCurrentPage();
            navigate(previousPage ? previousPage : '..', { relative: 'path' });
          }}
        >
          {interface_translate['Back'][language]}
        </button>
        <button
          onClick={() => {
            navigate('..', { relative: 'path' });
          }}
        >
          {interface_translate['Exit'][language]}
        </button>
        {roleAccess[userRole].permissions['Customer cargo list']
          .assign_storage_cell && (
          <SelectStorageCellBtn
            btnName={interface_translate['Assign storage cell'][language]}
            setSelectedStorageCell={async (storage_cell) => {
              const result = await updateStorageCell(storage_cell);
              return result;
            }}
            errors={selectStorageCellErrors}
          />
        )}
        {roleAccess[userRole].permissions['Customer cargo list']
          .submit_closing_documents &&
          customer_cargo.status == 'checked' && (
            <button
              onClick={(e) => {
                setViewSubmitClosingDocumentsModal(true);
              }}
            >
              {interface_translate['Submit the closing documents'][language]}
            </button>
          )}
        {roleAccess[userRole].permissions['Customer cargo list']
          .ready_to_issue &&
          customer_cargo.status == 'documents_loaded' && (
            <button
              onClick={async (e) => {
                setViewReadyToIssue(true);
              }}
            >
              {interface_translate['Ready to issue'][language]}
            </button>
          )}
        {customer_cargo.status == 'ready_to_issue' &&
          roleAccess[userRole].permissions['Customer cargo list']
            .issue_to_the_client && (
            <button
              onClick={(e) => {
                setViewTransferToClientConfirm(true);
              }}
            >
              {interface_translate['Issue to the client'][language]}
            </button>
          )}
      </div>
      {customer_cargo && (
        <div className={`viewData ${styles.viewData}`}>
          <OpeningBlock
            title={interface_translate['About customer cargo'][language]}
            open={true}
          >
            {attrsForRead.has('id') && (
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['customer cargo id'][language]}
                </div>
                <span>{customer_cargo.id}</span>
              </div>
            )}
            {attrsForRead.has('order_id') && (
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Order'][language]}
                </div>
                <span
                  className="linkToModel"
                  onClick={() =>
                    navigate(`/orders/${customer_cargo.order_id}`, {
                      state: { from: location },
                    })
                  }
                >
                  {customer_cargo.order_id}
                </span>
              </div>
            )}
            {attrsForRead.has('shipment_id') && (
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Shipment'][language]}
                </div>
                <span
                  className="linkToModel"
                  onClick={() =>
                    navigate(`/shipments/${customer_cargo.shipment_id}`, {
                      state: { from: location },
                    })
                  }
                >
                  {customer_cargo.shipment_id}
                </span>
              </div>
            )}
            {attrsForRead.has('content') && (
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Content'][language]}
                </div>
                <span>{customer_cargo.content}</span>
              </div>
            )}
            {attrsForRead.has('status_translate') && (
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Status'][language]}
                </div>
                <span>{customer_cargo.status_tranclate}</span>
              </div>
            )}
            {attrsForRead.has('total_amount') && (
              <div>
                <div className="openingBlockAttr">
                  <div className="openingBlockAttrName">
                    {interface_translate['Total amount ₽'][language]}
                  </div>
                  <span>{customer_cargo.total_amount}</span>
                </div>
              </div>
            )}
            {attrsForRead.has('weight_in_kg') && (
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Weight in kg'][language]}
                </div>
                <span>{customer_cargo.weight_in_kg}</span>
              </div>
            )}
            {attrsForRead.has('volume_in_m3') && (
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Volume in м³'][language]}
                </div>
                <span>{customer_cargo.volume_in_m3}</span>
              </div>
            )}
            {attrsForRead.has('storehouse_name') && (
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Storehouse'][language]}
                </div>
                <span
                  className="linkToModel"
                  onClick={() =>
                    navigate(`/storehouses/${customer_cargo.storehouse_id}`, {
                      state: { from: location },
                    })
                  }
                >
                  {customer_cargo.storehouse_name}
                </span>
              </div>
            )}
            {attrsForRead.has('storage_cell_name') && (
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Storage cell'][language]}
                </div>
                <span
                  className="linkToModel"
                  onClick={() =>
                    navigate(
                      `/storage_cells/${customer_cargo.storage_cell.id}`,
                      {
                        state: { from: location },
                      }
                    )
                  }
                >
                  {customer_cargo.storage_cell_name}
                </span>
              </div>
            )}
            {attrsForRead.has('invoice_documents') && (
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Invoice documents'][language]}
                </div>
                <div className="listDropDomnMedia">
                  {customer_cargo.invoice_documents.map((image, id) => {
                    let { mediaBlock, preViewImg } =
                      getPrevViewImgAndMediaBlockByImageObj(image, language);
                    return (
                      <DropDownMediaBlock
                        key={id}
                        preViewImg={preViewImg}
                        mediaBlock={mediaBlock}
                      />
                    );
                  })}
                </div>
              </div>
            )}
            {attrsForRead.has('media_content') && (
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Media content'][language]}
                </div>
                <div className="listDropDomnMedia">
                  {customer_cargo.media_content.map((image, id) => {
                    let { mediaBlock, preViewImg } =
                      getPrevViewImgAndMediaBlockByImageObj(image, language);
                    return (
                      <DropDownMediaBlock
                        key={id}
                        preViewImg={preViewImg}
                        mediaBlock={mediaBlock}
                      />
                    );
                  })}
                </div>
              </div>
            )}
            {attrsForRead.has('responsible') && (
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Responsible'][language]}
                </div>
                <div className="listModels">
                  {customer_cargo.responsible.map((model) => {
                    return (
                      <div
                        className="modelBlock"
                        key={model.id}
                      >
                        {`${model.surname} ${model.name} ${model.patronymic}`}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {attrsForRead.has('co_executors') && (
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Co executors'][language]}
                </div>
                <div className="listModels">
                  {customer_cargo.co_executors.map((model) => {
                    return (
                      <div
                        className="modelBlock"
                        key={model.id}
                      >
                        {`${model.surname} ${model.name} ${model.patronymic}`}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </OpeningBlock>
          <ShipmentRead
            shipment={shipment}
            products={[]}
            viewProducts={false}
            shipmentLogistics={shipmentLogistics}
          />
        </div>
      )}
      <div>
        <div className="openingBlockAttrName">
          {interface_translate['Products'][language]}
        </div>
        <ModelView
          tableAttrs={productTableAttrs}
          models={customer_cargo.customer_cargo_products}
          useSelected={false}
          usePagination={false}
          useSettings={false}
          handleModelClick={(model) =>
            navigate(`/products/${model.product.id}`, {
              state: { from: location },
            })
          }
        />
      </div>
      {viewSubmitClosingDocumentsModal && (
        <ModalWindow
          isActive={viewSubmitClosingDocumentsModal}
          setIsActive={setViewSubmitClosingDocumentsModal}
        >
          <div className={styles.submitClosingDocumentsContainer}>
            <div className={styles.controlBtns}>
              <button
                onClick={async () => {
                  const data = getDataForSubmitClosingDocuments();
                  if (!data) return;
                  const result =
                    await send_req_enter_information_about_signing_documents(
                      customer_cargo.id,
                      data,
                      setErrors,
                      resetGlobalMsg
                    );
                  if (result) {
                    setGlobalMsg(interface_translate['Data added'][language]);
                    resetGlobalError();
                    setErrors({});
                    requestGetCustomerCargo();
                    setViewSubmitClosingDocumentsModal(false);
                  }
                  setSubmitClosingDocumentsData({});
                }}
              >
                {interface_translate['Save'][language]}
              </button>
              <button onClick={() => setViewSubmitClosingDocumentsModal(false)}>
                {interface_translate['Exit'][language]}
              </button>
            </div>
            <div className={styles.fieldInput}>
              <div className={styles.fieldInputName}>
                {interface_translate['Final cost logistics'][language]}
              </div>
              <input
                type="text"
                value={submitClosingDocumentsData.final_cost_logistics}
                onChange={(e) => {
                  setSubmitClosingDocumentsData({
                    ...submitClosingDocumentsData,
                    final_cost_logistics: e.target.value,
                  });
                }}
              />
            </div>
            {errors['final_cost_logistics'] && (
              <div className="openingBlockErrorAttr">
                {api_error_translate[errors['final_cost_logistics'][0].split(":")[0]][language]}
              </div>
            )}
          </div>
        </ModalWindow>
      )}
      {!customer_cargo && <div>Пусто</div>}
    </div>
  );
};

export default CustomerCargoPage;
