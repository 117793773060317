import { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { api_error_translate } from '../../texts/error';
import { model_names } from '../../texts/model_names';
import { interface_translate } from '../../texts/interface';
import { statuses_translate } from '../../texts/statuses';
import usePageMovementHistory from '../../hooks/usePageMovementHistory';
import useCommon from '../../hooks/useCommon';
import OpeningBlock from '../../components/OpeningBlock';
import RelatedModelsCreate from '../../components/related_models_create/RelatedModelsCreate';
import ModalWindow from '../../components/ModalWindow';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import ProductCreatePage from '../../pages/product/ProductCreatePage';
import {
  admin,
  client_realtions_manager,
  supplier_realtions_manager,
} from '../../roles';
import roleAccess from '../../roleAccess';
import orderService from '../../services/order';
import counterpartyService from '../../services/counterparty';
import userService from '../../services/user';
import productService from '../../services/product';
import styles from './OrderPage.module.css';
import '../shared.css';
import { types } from '../../texts/types';

async function get_order(id) {
  const result = await orderService.get(id);
  return result;
}

async function get_user(id) {
  const result = await userService.get(id);
  return result;
}

async function delete_product(id, setGlobalError, language) {
  const result = await productService.delete(id, setGlobalError, language);
  return result;
}

async function get_suppliers(filters = {}, size = 50) {
  filters = { ...filters, group: 'supplier' };
  const suppliers_paginated = await counterpartyService.get_by_filters(
    filters,
    {
      size: size,
    }
  );
  return suppliers_paginated.data;
}

async function product_add_info_about_supplier(id, data, setErrors) {
  const result = await productService.add_info_about_supplier(
    id,
    data,
    setErrors
  );
  return result;
}

function get_product_for_display(
  product,
  order,
  language,
  userRole,
  default_suppliers,
  setViewAddSupplierModal,
  setAddSupplierData
) {
  if (!product.link) product.link = '';
  if (
    product.status == 'created' &&
    [admin, supplier_realtions_manager].includes(userRole)
  ) {
    product.add_supplier = (
      <button
        onClick={(e) => {
          e.stopPropagation();
          setViewAddSupplierModal(true);
          setAddSupplierData({
            product_id: product.id,
            supplierElementsForSelect: default_suppliers,
          });
        }}
      >
        {interface_translate['Add supplier'][language]}
      </button>
    );
  }
  product.status_translate = statuses_translate[product.status][language];
  product.price_and_currency =
    product.price && `${product.price} ${product.currency}`;
  product.qty_with_unit = `${product.qty} ${
    types[product.measurement_unit][language]
  }`;
  product.total_cost = product.price * product.qty;
  product.supplier_name = product.supplier?.name;
  product.storehouse_id = product.storage_cell
    ? product.storage_cell.shelving.storehouse.id
    : '';
  product.storehouse_name = product.storage_cell
    ? product.storage_cell.shelving.storehouse.name
    : '';
  product.storage_cell_name = product.storage_cell
    ? `${product.storage_cell.shelving.name}${product.storage_cell.number}`
    : '';
  product.client_name = order.client.name;

  return product;
}

const product_table_all_attrs = [
  {
    name: 'id',
    name_for_display: 'product id',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'client_name',
    name_for_display: 'client',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'order_id',
    name_for_display: 'order',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'name',
    name_for_display: 'name',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'status_translate',
    name_for_display: 'status',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'qty_with_unit',
    name_for_display: 'qty',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'price_and_currency',
    name_for_display: 'price',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'qty_ordered_products',
    name_for_display: 'qty ordered products',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'qty_products_in_china',
    name_for_display: 'qty products in china',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'qty_shipped_products',
    name_for_display: 'qty shipped products',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'qty_products_in_russia',
    name_for_display: 'qty products in russia',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'qty_issued_products',
    name_for_display: 'qty issued products',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'storehouse_name',
    name_for_display: 'storehouse',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'storage_cell_name',
    name_for_display: 'storage cell',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'supplier_name',
    name_for_display: 'supplier',
    translate_name_for_display: null,
    use_sort: true,
  },
];

const OrderPage = () => {
  const {
    setGlobalError,
    resetGlobalError,
    setGlobalMsg,
    resetGlobalMsg,
    language,
    userRole,
  } = useCommon();
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { pushPage, popPage, getCurrentPage } = usePageMovementHistory();
  const [order, setOrder] = useState();
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [createProductView, setCreateProductView] = useState(false);
  const [viewAddSupplierModal, setViewAddSupplierModal] = useState(false);
  const [addSupplierData, setAddSupplierData] = useState({ currency: 'cny' });
  const [addSupplierErrors, setAddSupplierErrors] = useState({});
  const [addFieldForAddSupplier, setAddFieldForAddSupplier] = useState(false);
  const [defautlSuppliers, setDefaultSuppliers] = useState([]);
  const [attrsForRead, setAttrsForRead] = useState(new Set());
  const [productTableAttrs, setProductTableAttrs] = useState([]);

  useEffect(() => {
    requestGetOrder();
    pushPage(location.pathname);
    const attrs_for_read = new Set(
      roleAccess[userRole].fieldRestrictions.Orders?.read
    );
    setAttrsForRead(attrs_for_read);
    console.log(userRole);
    const product_table_attrs = product_table_all_attrs
      .filter((attr_info) =>
        roleAccess[userRole].fieldRestrictions.Products.read_all.includes(
          attr_info.name
        )
      )
      .map((attr_info) => {
        return {
          ...attr_info,
          translate_name_for_display:
            interface_translate[attr_info.name_for_display][language],
        };
      });
    setProductTableAttrs(product_table_attrs);
  }, []);

  const requestGetOrder = async () => {
    const order_resp = await get_order(params.id);
    const responsible = [];
    for (const user_id of order_resp.responsible_ids) {
      const user = await get_user(user_id);
      responsible.push(user);
    }
    order_resp.responsible = responsible;
    const co_executors = [];
    for (const user_id of order_resp.co_executors_ids) {
      const user = await get_user(user_id);
      co_executors.push(user);
    }
    order_resp.co_executors = co_executors;
    let total_amount = 0;
    let add_field_for_add_supplier = false;
    const default_suppliers = await get_suppliers();
    setDefaultSuppliers(default_suppliers);

    for (let product of order_resp.products) {
      if (product.status == 'created' && !add_field_for_add_supplier) {
        add_field_for_add_supplier = true;
      }
      product = get_product_for_display(
        product,
        order_resp,
        language,
        userRole,
        default_suppliers,
        setViewAddSupplierModal,
        setAddSupplierData
      );
      if (product.currency == 'cny') {
        total_amount += product.total_cost * order_resp.rate_cny_to_rub;
      } else if (product.currency == 'usd') {
        total_amount += product.total_cost * order_resp.rate_dollar_to_rub;
      } else if (product.currency == 'rub') {
        total_amount += product.total_cost;
      }
    }
    if (
      !addFieldForAddSupplier &&
      add_field_for_add_supplier &&
      [admin, supplier_realtions_manager].includes(userRole)
    ) {
      setProductTableAttrs((prevData) => {
        return [
          ...prevData,
          {
            name: 'add_supplier',
            name_for_display: '',
            translate_name_for_display: '',
            use_sort: false,
          },
        ];
      });
      setAddFieldForAddSupplier(true);
    }
    order_resp.total_amount = total_amount;
    order_resp.completed = order_resp.completed ? '+' : '-';

    setOrder(order_resp);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      let filters = {};
      if (addSupplierData.supplierInputValue)
        filters['name'] = addSupplierData.supplierInputValue;
      const new_elements = await get_suppliers(filters);
      setAddSupplierData({
        ...addSupplierData,
        supplierElementsForSelect: new_elements,
      });
    }, 500);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [addSupplierData.supplierInputValue]);

  const getDataFromAddSupplierData = () => {
    if (
      !addSupplierData.hasOwnProperty('supplier') ||
      addSupplierData.supplier == null
    ) {
      setAddSupplierErrors({
        supplier_id: ['There are blank fields left'],
      });
      return;
    }
    const data = {
      supplier_id: addSupplierData.supplier.id,
    };
    if (addSupplierData.price) {
      data.price = addSupplierData.price;
    }
    if (addSupplierData.link) {
      data.link = addSupplierData.link;
    }
    if (addSupplierData.currency) {
      data['currency'] = addSupplierData.currency;
    }
    return data;
  };

  const setProductsInOrder = (products) => {
    setOrder({
      ...order,
      products: products,
    });
  };

  const deleteModels = async () => {
    setProductsInOrder(
      order.products.filter((model) => !selectedProducts.includes(model.id))
    );
    for (const id of selectedProducts) {
      const result = await delete_product(id, setGlobalError, language);
      if (!result) {
        requestGetOrder();
      }
    }
  };

  const addProductInOrder = (new_product) => {
    new_product = get_product_for_display(
      new_product,
      order,
      language,
      defautlSuppliers,
      setViewAddSupplierModal,
      setAddSupplierData
    );
    order.total_amount += new_product.total_cost * order.rate_cny_to_rub;
    if (
      !addFieldForAddSupplier &&
      new_product.status == 'created' &&
      [admin, supplier_realtions_manager].includes(userRole)
    ) {
      setProductTableAttrs((prevData) => {
        return [
          ...prevData,
          {
            name: 'add_supplier',
            name_for_display: '',
            translate_name_for_display: '',
            use_sort: false,
          },
        ];
      });
      setAddFieldForAddSupplier(true);
    }
    new_product = get_product_for_display(
      new_product,
      order,
      language,
      userRole,
      defautlSuppliers,
      setViewAddSupplierModal,
      setAddSupplierData
    );
    setOrder({
      ...order,
      products: [new_product, ...order.products],
    });
  };

  return (
    <div className={`pageViewModel`}>
      <div className="controls">
        {roleAccess[userRole].permissions.Orders.edit && (
          <button
            onClick={() => {
              navigate('edit');
            }}
          >
            {interface_translate['Edit'][language]}
          </button>
        )}
        <button
          onClick={() => {
            popPage();
            const previousPage = getCurrentPage();
            navigate(previousPage ? previousPage : '..', { relative: 'path' });
          }}
        >
          {interface_translate['Back'][language]}
        </button>
        <button
          onClick={() => {
            navigate('..', { relative: 'path' });
          }}
        >
          {interface_translate['Exit'][language]}
        </button>
        {roleAccess[userRole].permissions.Orders.create_delivery && (
          <button
            onClick={() =>
              navigate('/deliveries/create', {
                relative: 'path',
                state: { order_id: order.id },
              })
            }
          >
            {interface_translate['Create delivery'][language]}
          </button>
        )}
      </div>
      {order && (
        <>
          <div className={'viewData'}>
            <OpeningBlock
              title={interface_translate['About order'][language]}
              open={true}
            >
              {attrsForRead.has('id') && (
                <div className="openingBlockAttr">
                  <div className="openingBlockAttrName">
                    {interface_translate['order id'][language]}
                  </div>
                  <span>{order.id}</span>
                </div>
              )}
              {attrsForRead.has('id') && (
                <div className="openingBlockAttr">
                  <div className="openingBlockAttrName">
                    {interface_translate['Client'][language]}
                  </div>
                  <span
                    className="linkToModel"
                    onClick={() =>
                      navigate(`/counterparties/${order.client.id}`, {
                        state: { from: location },
                      })
                    }
                  >
                    {order.client.name}
                  </span>
                </div>
              )}
              {attrsForRead.has('estimated_cost_logistics') && (
                <div>
                  <div className="openingBlockAttr">
                    <div className="openingBlockAttrName">
                      {
                        interface_translate['Estimated cost logistics'][
                          language
                        ]
                      }
                    </div>
                    <span>{order.estimated_cost_logistics}</span>
                  </div>
                </div>
              )}
              {attrsForRead.has('final_cost_logistics') && (
                <div>
                  <div className="openingBlockAttr">
                    <div className="openingBlockAttrName">
                      {interface_translate['Final cost logistics'][language]}
                    </div>
                    <span>{order.final_cost_logistics}</span>
                  </div>
                </div>
              )}
              {attrsForRead.has('rate_cny_to_rub') && (
                <div>
                  <div className="openingBlockAttr">
                    <div className="openingBlockAttrName">
                      {interface_translate['Rate cny to rub'][language]}
                    </div>
                    <span>{order.rate_cny_to_rub}</span>
                  </div>
                </div>
              )}
              {attrsForRead.has('rate_usd_to_rub') && (
                <div>
                  <div className="openingBlockAttr">
                    <div className="openingBlockAttrName">
                      {interface_translate['Rate usd to rub'][language]}
                    </div>
                    <span>{order.rate_dollar_to_rub}</span>
                  </div>
                </div>
              )}
              {attrsForRead.has('completed') && (
                <div>
                  <div className="openingBlockAttr">
                    <div className="openingBlockAttrName">
                      {interface_translate['Completed'][language]}
                    </div>
                    <span>{order.completed}</span>
                  </div>
                </div>
              )}
              {attrsForRead.has('total_amount') && (
                <div>
                  <div className="openingBlockAttr">
                    <div className="openingBlockAttrName">
                      {interface_translate['Total amount ₽'][language]}
                    </div>
                    <span>{order.total_amount}</span>
                  </div>
                </div>
              )}
              {attrsForRead.has('responsible') && (
                <div className="openingBlockAttr">
                  <div className="openingBlockAttrName">
                    {interface_translate['Responsible'][language]}
                  </div>
                  <div className="listModels">
                    {order.responsible.map((model) => {
                      return (
                        <div
                          className="modelBlock"
                          key={model.id}
                        >
                          {`${model.surname} ${model.name} ${model.patronymic}`}
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
              {attrsForRead.has('co_executors') && (
                <div className="openingBlockAttr">
                  <div className="openingBlockAttrName">
                    {interface_translate['Co executors'][language]}
                  </div>
                  <div className="listModels">
                    {order.co_executors.map((model) => {
                      return (
                        <div
                          className="modelBlock"
                          key={model.id}
                        >
                          {`${model.surname} ${model.name} ${model.patronymic}`}
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </OpeningBlock>
          </div>
          <div className={styles.inputProducts}>
            <div className="openingBlockAttrName">
              {interface_translate['Products'][language]}
            </div>
            <RelatedModelsCreate
              section="Products"
              tableAttrs={productTableAttrs}
              models={order.products}
              setModels={setProductsInOrder}
              setSelectedModels={setSelectedProducts}
              addModel={() => setCreateProductView(true)}
              deleteModels={deleteModels}
              handleModelClick={(model) =>
                navigate(`/products/${model.id}`, {
                  state: { from: location },
                })
              }
            />
          </div>
        </>
      )}
      {order && createProductView && (
        <ModalWindow
          isActive={createProductView}
          setIsActive={setCreateProductView}
        >
          <div className={styles.mоdalCreateProduct}>
            <ProductCreatePage
              setGlobalError={setGlobalError}
              resetGlobalError={resetGlobalError}
              setGlobalMsg={setGlobalMsg}
              resetGlobalMsg={resetGlobalMsg}
              orderId={order.id}
              addProduct={addProductInOrder}
              handleExit={() => setCreateProductView(false)}
            />
          </div>
        </ModalWindow>
      )}
      {!order && <div>Пусто</div>}
      {viewAddSupplierModal && (
        <ModalWindow
          isActive={viewAddSupplierModal}
          setIsActive={(v) => {
            setAddSupplierData({});
            setAddSupplierErrors({});
            setViewAddSupplierModal(v);
          }}
        >
          <div className="modalWindowDefaultContainer">
            <div>
              <button
                onClick={async () => {
                  const product_id = addSupplierData.product_id;
                  const data = getDataFromAddSupplierData();
                  if (data == null) {
                    return;
                  }
                  const result = await product_add_info_about_supplier(
                    product_id,
                    data,
                    setAddSupplierErrors
                  );
                  if (result) {
                    setAddSupplierData({});
                    setAddSupplierErrors({});
                    setViewAddSupplierModal(false);
                    requestGetOrder();
                  }
                }}
              >
                {interface_translate['Save'][language]}
              </button>
              <button
                onClick={() => {
                  setAddSupplierData({});
                  setViewAddSupplierModal(false);
                }}
              >
                {interface_translate['Exit'][language]}
              </button>
            </div>
            <div className="openingBlockAttr">
              <div className="openingBlockAttrName">
                {interface_translate['Link'][language]}
              </div>
              <input
                type="text"
                onChange={(e) => {
                  setAddSupplierData({
                    ...addSupplierData,
                    link: e.target.value,
                  });
                }}
              />
            </div>
            {addSupplierErrors['link'] && (
              <div className="openingBlockErrorAttr">
                {api_error_translate[addSupplierErrors['link'][0]][language]}
              </div>
            )}
            <div>
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Currency'][language]}
                </div>
                <select
                  onChange={(e) => {
                    setAddSupplierData({
                      ...addSupplierData,
                      currency: e.target.value,
                    });
                  }}
                  defaultValue="cny"
                >
                  <option value="rub">RUB</option>
                  <option value="usd">USD</option>
                  <option value="cny">CNY</option>
                </select>
              </div>
              {addSupplierErrors['currency'] && (
                <div className="openingBlockErrorAttr">
                  {
                    api_error_translate[addSupplierErrors['currency'][0]][
                      language
                    ]
                  }
                </div>
              )}
            </div>
            <div className="openingBlockAttr">
              <div className="openingBlockAttrName">
                {interface_translate['Price'][language]}
              </div>
              <input
                type="text"
                onChange={(e) => {
                  setAddSupplierData({
                    ...addSupplierData,
                    price: e.target.value,
                  });
                }}
              />
            </div>
            {addSupplierErrors['price'] && (
              <div className="openingBlockErrorAttr">
                {api_error_translate[
                  addSupplierErrors['price'][0].split(":")[0]
                ][language]}
              </div>
            )}
            <div className="openingBlockAttr">
              <div className="openingBlockAttrName">
                {interface_translate['Supplier'][language]}
              </div>
              <Autocomplete
                onChange={(e, newElement) => {
                  setAddSupplierData({
                    ...addSupplierData,
                    supplier: newElement,
                  });
                }}
                options={addSupplierData.supplierElementsForSelect}
                getOptionLabel={(model) => model.name}
                onInputChange={(e, value) => {
                  setAddSupplierData({
                    ...addSupplierData,
                    supplierInputValue: value,
                    supplierElementsForSelect: [],
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label={interface_translate['Supplier'][language]}
                    size="small"
                  />
                )}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                loading={addSupplierData.supplierElementsForSelect?.length == 0}
              />
            </div>
            {addSupplierErrors['supplier_id'] && (
              <div className="openingBlockErrorAttr">
                {
                  api_error_translate[addSupplierErrors['supplier_id'][0]][
                    language
                  ]
                }
              </div>
            )}
          </div>
        </ModalWindow>
      )}
    </div>
  );
};

export default OrderPage;
