import { useState, React, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import usePageMovementHistory from '../../hooks/usePageMovementHistory';
import useCommon from '../../hooks/useCommon';
import { api_error_translate } from '../../texts/error';
import { interface_translate } from '../../texts/interface.js';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import OpeningBlock from '../../components/OpeningBlock';
import ListAttrAutocomplete from '../../components/ListAttrAutocomplete';
import roleAccess from '../../roleAccess.js';
import orderService from '../../services/order.js';
import userService from '../../services/user.js';
import counterpartyService from '../../services/counterparty.js';
import styles from './OrderCreatePage.module.css';
import '../shared.css';
import ResponsibleListInput from '../../components/input/ResponsibleListInput.jsx';
import CoExecutorsInput from '../../components/input/CoExecutorsInput.jsx';

async function create(data, setErrors) {
  const result = await orderService.create(data, setErrors);
  return result;
}

async function get_users(filters = {}, size = 50) {
  const result = await userService.get_by_filters(filters, { size: size });
  return result.data;
}

async function get_clients(filters = {}, size = 50) {
  filters = { group: 'client', ...filters };
  const clients = await counterpartyService.get_by_filters(filters, {
    size: size,
  });
  return clients.data;
}

const OrderCreatePage = (props) => {
  const {
    setGlobalError,
    resetGlobalError,
    setGlobalMsg,
    resetGlobalMsg,
    language,
    userRole,
    user,
  } = useCommon();
  const navigate = useNavigate();
  const location = useLocation();
  const { pushPage, popPage, getCurrentPage } = usePageMovementHistory();
  const [errors, setErrors] = useState({});
  const [modelData, setModelData] = useState({});
  const [responsible, setResponsible] = useState({});
  const [initialResponsible, setInitialResponsible] = useState([]);
  const [coExecutors, setCoExecutors] = useState({});
  const [defUsers, setDefUsers] = useState(null);
  const [clientInputValue, setClientInputValue] = useState(null);
  const [clientElementsForSelect, setClientElementsForSelect] = useState([]);
  const [attrsForCreate, setAttrsForCreate] = useState(new Set());

  useEffect(() => {
    const req = async () => {
      const users = await get_users({});
      setDefUsers(users);
    };
    req();
    pushPage(location.pathname);
    const attrsForCreate = new Set(
      roleAccess[userRole].fieldRestrictions.Orders?.create
    );
    setAttrsForCreate(attrsForCreate);
  }, []);

  useEffect(() => {
    setInitialResponsible([user]);
  }, [user]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      let filters = {};
      if (clientInputValue) filters['name'] = clientInputValue;
      const new_elements = await get_clients(filters);
      setClientElementsForSelect(new_elements);
    }, 500);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [clientInputValue]);

  const getDataFromAttr = () => {
    if (modelData.client == null) {
      setErrors({
        client_id: ['There are blank fields left'],
      });
      return;
    }
    const responsible_ids = Object.values(responsible).map((m) =>
      m ? m.id : null
    );
    for (let id of responsible_ids) {
      if (id === null) {
        setErrors({
          responsible_ids: ['There are blank fields left'],
        });
        return;
      }
    }
    const co_executor_ids = Object.values(coExecutors).map((m) =>
      m ? m.id : null
    );
    for (let id of co_executor_ids) {
      if (id === null) {
        setErrors({
          co_executors_ids: ['There are blank fields left'],
        });
        return;
      }
    }
    const data = {
      ...modelData,
      client_id: modelData.client.id,
      responsible_ids: responsible_ids,
      co_executor_ids: co_executor_ids,
    };
    delete data['client'];
    return data;
  };

  if (!user) return;
  return (
    <div className="pageCreateModel">
      <div className={styles.saveAndExitBtns}>
        <button
          onClick={async () => {
            const data = getDataFromAttr();
            if (!data) return;
            const result = await create(
              data,
              setErrors,
              setGlobalError,
              resetGlobalMsg
            );
            if (result) {
              setGlobalMsg(interface_translate['Order created'][language]);
              resetGlobalError();
              setErrors({});
              navigate('..', { relative: 'path' });
            }
          }}
        >
          {interface_translate['Create'][language]}
        </button>
        <button
          onClick={() => {
            popPage();
            const previousPage = getCurrentPage();
            navigate(previousPage ? previousPage : '..', { relative: 'path' });
          }}
        >
          {interface_translate['Back'][language]}
        </button>
        <button
          onClick={() => {
            navigate('..', { relative: 'path' });
          }}
        >
          {interface_translate['Exit'][language]}
        </button>
      </div>
      <div className="inputData">
        <OpeningBlock
          title={interface_translate['About order'][language]}
          open={true}
        >
          {attrsForCreate.has('client_name') && (
            <div>
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Client'][language]}
                </div>
                <Autocomplete
                  onChange={(e, newElement) => {
                    setModelData({
                      ...modelData,
                      client: newElement,
                    });
                  }}
                  options={clientElementsForSelect}
                  getOptionLabel={(model) => model.name}
                  onInputChange={(e, value) => {
                    setClientInputValue(value);
                    setClientElementsForSelect([]);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label={interface_translate['Client'][language]}
                      size="small"
                    />
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  loading={clientElementsForSelect.length == 0}
                />
              </div>
              {errors['client_id'] && (
                <div className="openingBlockErrorAttr">
                  {api_error_translate[errors['client_id'][0]][language]}
                </div>
              )}
            </div>
          )}
          {attrsForCreate.has('estimated_cost_logistics') && (
            <div>
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Estimated cost logistics'][language]}
                </div>
                <input
                  type="text"
                  onChange={(e) => {
                    setModelData({
                      ...modelData,
                      estimated_cost_logistics: e.target.value,
                    });
                  }}
                />
              </div>
              {errors['estimated_cost_logistics'] && (
                <div className="openingBlockErrorAttr">
                  {
                    api_error_translate[
                      errors['estimated_cost_logistics'][0].split(":")[0]
                    ][language]
                  }
                </div>
              )}
            </div>
          )}
          {attrsForCreate.has('rate_cny_to_rub') && (
            <div>
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Rate cny to rub'][language]}
                </div>
                <input
                  type="text"
                  onChange={(e) => {
                    setModelData({
                      ...modelData,
                      rate_cny_to_rub: e.target.value,
                    });
                  }}
                />
              </div>
              {errors['rate_cny_to_rub'] && (
                <div className="openingBlockErrorAttr">
                  {
                    api_error_translate[
                      errors['rate_cny_to_rub'][0].split(":")[0]
                    ][language]
                  }
                </div>
              )}
            </div>
          )}
          {attrsForCreate.has('rate_usd_to_rub') && (
            <div>
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Rate usd to rub'][language]}
                </div>
                <input
                  type="text"
                  onChange={(e) => {
                    setModelData({
                      ...modelData,
                      rate_dollar_to_rub: e.target.value,
                    });
                  }}
                />
              </div>
              {errors['rate_dollar_to_rub'] && (
                <div className="openingBlockErrorAttr">
                  {
                    api_error_translate[
                      errors['rate_dollar_to_rub'][0].split(":")[0]
                    ][language]
                  }
                </div>
              )}
            </div>
          )}
          {attrsForCreate.has('responsible') && (
            <div>
              <ResponsibleListInput
                containerClass={'openingBlockAttr'}
                attrNameClass={'openingBlockAttrName'}
                responsible={responsible}
                setSelectedElement={(value) => {
                  setResponsible((prevData) => {
                    return { ...prevData, ...value };
                  });
                }}
                deleteSelectedElement={(id) => {
                  setResponsible((prevElements) => {
                    delete prevElements[id];
                    return { ...prevElements };
                  });
                }}
                initialElements={initialResponsible}
                defaultElements={defUsers}
              />
              {errors['responsible_ids'] && (
                <div className="openingBlockErrorAttr">
                  {api_error_translate[errors['responsible_ids'][0]][language]}
                </div>
              )}
            </div>
          )}
          {attrsForCreate.has('co_executors') && (
            <div>
              <CoExecutorsInput
                containerClass={'openingBlockAttr'}
                attrNameClass={'openingBlockAttrName'}
                coExecutors={coExecutors}
                setSelectedElement={(value) => {
                  setCoExecutors((prevData) => {
                    return { ...prevData, ...value };
                  });
                }}
                deleteSelectedElement={(id) => {
                  setCoExecutors((prevElements) => {
                    delete prevElements[id];
                    return { ...prevElements };
                  });
                }}
                defaultElements={defUsers}
              />
              {errors['co_executors_ids'] && (
                <div className="openingBlockErrorAttr">
                  {api_error_translate[errors['co_executors_ids'][0]][language]}
                </div>
              )}
            </div>
          )}
        </OpeningBlock>
      </div>
    </div>
  );
};

export default OrderCreatePage;
