import apiRequester from '../ApiRequester';
import { model_names } from '../texts/model_names.js';
import { api_error_translate } from '../texts/error.js';

class ShipmentService {
  async create(data, setErrors, setGlobalError, resetGlobalMsg, language) {
    const jsonData = JSON.stringify(data);
    const co_executor_ids_already_in_responsible_ids_pattern =
      /ids:.*already in responsible_ids/;
    const quantityErrorPattern =
      /Quantity of (\d+(?:\.\d+)?) exceeds available stock of (\d+(?:\.\d+)?) for product ID: (\w+)\./;
    try {
      await apiRequester.post('/shipments', jsonData);
      return true;
    } catch (err) {
      if (err.response.status !== 200) {
        if (err.response.status === 400) {
          const err_msg = err.response.data.errors;
          if (err_msg === 'shipment already exists') {
            setErrors({});
            setGlobalError(api_error_translate[err_msg][language]);
            resetGlobalMsg();
            return;
          } else if (
            err_msg.hasOwnProperty('co_executors_ids') &&
            co_executor_ids_already_in_responsible_ids_pattern.test(
              err_msg['co_executors_ids']
            )
          ) {
            setErrors({
              co_executors_ids: ['co_executors_ids already in responsible_ids'],
            });
            return;
          } else if (err_msg.hasOwnProperty('shipment_product.qty')) {
            const match = quantityErrorPattern.exec(
              err_msg['shipment_product.qty'][0]
            );
            const [_, quantityOrdered, availableStock, productId] = match;
            setErrors({
              'shipment_product.qty': {
                msg: err_msg,
                productId,
                availableStock,
                quantityOrdered,
              },
            });
            return;
          } else if (err_msg.hasOwnProperty('shipment_products')) {
            setErrors({
              shipment_products: err_msg['shipment_products']
            });
            return;
          }
          setErrors({ ...err_msg });
          resetGlobalMsg();
        }
        throw new Error(`HTTP error: ${err.response.status}`);
      }
      console.error(`Could not fetch data: ${err}`);
    }
  }

  async get(id) {
    try {
      const response = await apiRequester.get(`/shipments/${id}`);
      const data = response.data;
      return data;
    } catch (error) {
      console.error(`Could not fetch data: ${error}`);
      throw error;
    }
  }

  async get_by_filters(filterData, paginationSettings = { size: 20 }) {
    const jsonData = JSON.stringify(filterData);
    try {
      const response = await apiRequester.post(`/shipments/filters`, jsonData, {
        params: paginationSettings,
      });
      const data = response.data;
      return data;
    } catch (error) {
      console.error(`Could not fetch data: ${error}`);
      throw error;
    }
  }

  async get_logistics(id) {
    try {
      const response = await apiRequester.get(`/shipments/${id}/get_logistics`);
      const data = response.data;
      return data;
    } catch (err) {
      if (err.response.status !== 200) {
        console.log(`HTTP error: ${err.response.status}`);
      }
    }
  }

  async get_packing() {
    const response = await apiRequester.get(`/shipments/packing`);
    return response.data;
  }

  async get_statuses() {
    const response = await apiRequester.get(`/shipments/statuses`);
    return response.data;
  }

  async update(id, data, setErrors, setGlobalError, resetGlobalMsg, language) {
    const co_executor_ids_already_in_responsible_ids_pattern =
      /ids: .* already in responsible_ids/;
    const quantityErrorPattern =
      /Quantity of (\d+(?:\.\d+)?) exceeds available stock of (\d+(?:\.\d+)?) for product ID: (\w+)\./;
    try {
      const formData = new FormData();
      if (data.order_id !== undefined) {
        formData.append('order_id', data.order_id);
      }
      if (data.shipment_products !== undefined) {
        if (data.shipment_products.length > 0) {
          data.shipment_products.forEach((m) => {
            formData.append(`shipment_products`, JSON.stringify(m));
          });
        } else {
          formData.append('shipment_products', -1);
        }
      }
      if (data.packing !== undefined) {
        formData.append('packing', data.packing);
      }
      if (data.status !== undefined) {
        formData.append('status', data.status);
      }
      if (data.weight_in_kg !== undefined) {
        formData.append(
          'weight_in_kg',
          data.weight_in_kg ? data.weight_in_kg : null
        );
      }
      if (data.volume_in_m3 !== undefined) {
        formData.append(
          'volume_in_m3',
          data.volume_in_m3 ? data.volume_in_m3 : null
        );
      }
      if (data.number_of_seats !== undefined) {
        formData.append('number_of_seats', data.number_of_seats);
      }
      if (data.storage_cell_id !== undefined) {
        if (data.storage_cell_id !== undefined) {
          formData.append('storage_cell_id', data.storage_cell_id);
        }
      }
      if (data.responsible_ids !== undefined) {
        if (data.responsible_ids.length > 0) {
          data.responsible_ids.forEach((m) => {
            formData.append(`responsible_ids`, m);
          });
        } else {
          formData.append('responsible_ids', -1);
        }
      }
      if (data.co_executors_ids !== undefined) {
        if (data.co_executors_ids.length > 0) {
          data.co_executors_ids?.forEach((m) => {
            formData.append(`co_executors_ids`, m);
          });
        } else {
          formData.append('co_executors_ids', -1);
        }
      }
      if (data.logistics_documents_for_create !== undefined) {
        data.logistics_documents_for_create.forEach((file) => {
          if (file instanceof File || file instanceof Blob) {
            console.log('Adding document to formData:', file.name);
            formData.append('logistics_documents_for_create', file);
          } else if (Array.isArray(file)) {
            file.forEach(f => {
              if (f instanceof File || f instanceof Blob) {
                console.log('Adding document to formData:', f.name);
                formData.append('logistics_documents_for_create', f);
              } else {
                console.error('Invalid document type in array:', typeof f);
              }
            });
          } else {
            console.error('Invalid document type:', typeof file);
          }
        });
      }
      if (data.logistics_documents_id_for_delete !== undefined) {
        if (data.logistics_documents_id_for_delete.length > 0) {
          data.logistics_documents_id_for_delete?.forEach((m) => {
            formData.append(`logistics_documents_id_for_delete`, m);
          });
        } else {
          formData.append('logistics_documents_id_for_delete', -1);
        }
      }
      if (data.media_content_for_create !== undefined) {
        data.media_content_for_create.forEach((file) => {
          if (file instanceof File || file instanceof Blob) {
            console.log('Adding file to formData:', file.name);
            formData.append('media_content_for_create', file);
          } else if (Array.isArray(file)) {
            file.forEach(f => {
              if (f instanceof File || f instanceof Blob) {
                console.log('Adding file to formData:', f.name);
                formData.append('media_content_for_create', f);
              } else {
                console.error('Invalid file type in array:', typeof f);
              }
            });
          } else {
            console.error('Invalid file type:', typeof file);
          }
        });
      }
      if (data.media_content_id_for_delete !== undefined) {
        if (data.media_content_id_for_delete.length > 0) {
          data.media_content_id_for_delete?.forEach((m) => {
            formData.append(`media_content_id_for_delete`, m);
          });
        } else {
          formData.append('media_content_id_for_delete', -1);
        }
      }
      await apiRequester.patch(`/shipments/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return true;
    } catch (err) {
      if (err.response.status !== 200) {
        if (err.response.status === 400) {
          const detail_msg = err.response.data.detail;
          const err_msg = err.response.data.errors;
          if (
            err_msg.hasOwnProperty('co_executors_ids') &&
            co_executor_ids_already_in_responsible_ids_pattern.test(
              err_msg['co_executors_ids']
            )
          ) {
            setErrors({
              co_executors_ids: ['co_executors_ids already in responsible_ids'],
            });
            return;
          } else if (detail_msg === 'shipment already exists') {
            setErrors({});
            setGlobalError(api_error_translate[detail_msg][language]);
            resetGlobalMsg();
          } else if (err_msg.hasOwnProperty('shipment_product.qty')) {
            const match = quantityErrorPattern.exec(
              err_msg['shipment_product.qty'][0]
            );
            const [_, quantityOrdered, availableStock, productId] = match;
            setErrors({
              'shipment_product.qty': {
                msg: err_msg,
                productId,
                availableStock,
                quantityOrdered,
              },
            });
            return;
          }
          setErrors({ ...err_msg });
          return;
        }
        throw new Error(`HTTP error: ${err.response.status}`);
      }
      console.error(`Could not fetch data: ${err}`);
    }
  }

  async enter_white_logistics(
    id,
    data,
    setErrors,
    setGlobalError,
    resetGlobalMsg,
    language
  ) {
    try {
      const jsonData = JSON.stringify(data);
      await apiRequester.post(
        `/shipments/${id}/enter_white_logistics`,
        jsonData
      );
      return true;
    } catch (err) {
      if (err.response.status !== 200) {
        if (err.response.status === 400) {
          const detail_msg = err.response.data.detail;
          const err_msg = err.response.data.errors;
          if (detail_msg === 'already has logistic: white') {
            setErrors({});
            setGlobalError(api_error_translate[detail_msg][language]);
            resetGlobalMsg();
          }
          setErrors({ ...err_msg });
          return;
        }
        throw new Error(`HTTP error: ${err.response.status}`);
      }
    }
  }

  async enter_cargo_logistics(
    id,
    data,
    setErrors,
    setGlobalError,
    resetGlobalMsg,
    language
  ) {
    try {
      const jsonData = JSON.stringify(data);
      await apiRequester.post(
        `/shipments/${id}/enter_cargo_logistics`,
        jsonData
      );
      return true;
    } catch (err) {
      if (err.response.status !== 200) {
        if (err.response.status === 400) {
          const detail_msg = err.response.data.detail;
          const err_msg = err.response.data.errors;
          if (detail_msg === 'already has logistic: cargo') {
            setErrors({});
            setGlobalError(api_error_translate[detail_msg][language]);
            resetGlobalMsg();
          }
          setErrors({ ...err_msg });
          return;
        }
        throw new Error(`HTTP error: ${err.response.status}`);
      }
    }
  }

  async enter_bulk_white_logistics(data, setErrors) {
    try {
      const jsonData = JSON.stringify(data);
      await apiRequester.post("/shipments/bulk_white_logistics", jsonData);
      return true;
    } catch (err) {
      if (err.response.status === 400 && err.response.data?.errors) {
        setErrors(err.response.data.errors);
        return false;
      }
      throw err;
    }
  }

  async enter_bulk_cargo_logistics(data, setErrors) {
    try {
      const jsonData = JSON.stringify(data);
      await apiRequester.post("/shipments/bulk_cargo_logistics", jsonData);
      return true;
    } catch (err) {
      if (err.response.status === 400 && err.response.data?.errors) {
        setErrors(err.response.data.errors);
        return false;
      }
      throw err;
    }
  }

  async add_info_about_assembling(
    id,
    data,
    setErrors,
    setGlobalError,
    resetGlobalMsg,
    language
  ) {
    try {
      const jsonData = JSON.stringify(data);
      await apiRequester.post(
        `/shipments/${id}/add_info_about_assembling`,
        jsonData
      );
      return true;
    } catch (err) {
      if (err.response.status !== 200) {
        if (err.response.status === 400) {
          const detail_msg = err.response.data.detail;
          const err_msg = err.response.data.errors;
          setErrors({ ...err_msg });
          return;
        }
        throw new Error(`HTTP error: ${err.response.status}`);
      }
    }
  }

  async scan_barcode(id) {
    try {
      await apiRequester.get(`/shipments/scan_barcode?shipment_id=${id}`);
    } catch (err) {
      if (err.response.status !== 200) {
        throw new Error(`HTTP error: ${err.response.status}`);
      }
      console.error(`Could not fetch data: ${err}`);
    }
  }

  async delete(id, setErrors, setGlobalError, resetGlobalMsg, language) {
    try {
      const response = await apiRequester.delete(`/shipments/${id}`);
      return response.data;
    } catch (err) {
      if (err.response.status !== 200) {
        if (err.response.status === 400) {
          const detail_msg = err.response.data.detail;
          const err_msg = err.response.data.errors;
          if (err_msg === 'foreign connection') {
            let msg = `${api_error_translate['linked to another model'][language]}: ${model_names[detail_msg][language]}`;
            setGlobalError(msg);
          }
        }
        return;
      }
      console.error(`Could not fetch data: ${err}`);
      throw err;
    }
  }
}

const shipmentService = new ShipmentService();
export default shipmentService;
