import { useState, React, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import usePageMovementHistory from '../../hooks/usePageMovementHistory';
import useCommon from '../../hooks/useCommon';
import { api_error_translate } from '../../texts/error';
import { interface_translate } from '../../texts/interface';
import { types } from '../../texts/types.js';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import OpeningBlock from '../../components/OpeningBlock';
import ListAttrMedia from '../../components/ListAttrMedia';
import roleAccess from '../../roleAccess.js';
import counterpartyService from '../../services/counterparty';
import orderService from '../../services/order';
import userService from '../../services/user';
import sharedService from '../../services/shared';
import styles from './ProductCreatePage.module.css';
import '../shared.css';
import productService from '../../services/product';
import ResponsibleListInput from '../../components/input/ResponsibleListInput.jsx';
import CoExecutorsInput from '../../components/input/CoExecutorsInput.jsx';

async function create(
  data,
  setErrors,
  setGlobalError,
  resetGlobalMsg,
  language
) {
  const result = await productService.create(
    data,
    setErrors,
    setGlobalError,
    resetGlobalMsg,
    language
  );
  return result;
}

async function get_users(filters = {}, size = 50) {
  const users_paginated = await userService.get_by_filters(filters, {
    size: size,
  });
  return users_paginated.data;
}

async function get_user(id) {
  const user = await userService.get(id);
  return user;
}

async function get_orders(filters = {}, size = 50) {
  const orders_paginated = await orderService.get_by_filters(filters, {
    size: size,
  });
  return orders_paginated.data;
}

async function get_suppliers(filters = {}, size = 50) {
  filters = { ...filters, group: 'supplier' };
  const suppliers_paginated = await counterpartyService.get_by_filters(
    filters,
    {
      size: size,
    }
  );
  return suppliers_paginated.data;
}

async function get_unit_names() {
  const unit_names = await sharedService.get_unit_names();
  return unit_names;
}

const ProductCreatePage = (props) => {
  const { orderId, addProduct, handleExit } = props;
  const {
    setGlobalError,
    resetGlobalError,
    setGlobalMsg,
    resetGlobalMsg,
    language,
    userRole,
    user,
  } = useCommon();
  const navigate = useNavigate();
  const location = useLocation();
  const { pushPage, popPage, getCurrentPage } = usePageMovementHistory();
  const [errors, setErrors] = useState({});
  const [modelData, setModelData] = useState({
    currency: 'cny',
    measurement_unit: 'piece',
  });
  const [unitNames, setUnitNames] = useState([]);
  const [orderInputValue, setOrderInputValue] = useState(null);
  const [orderElementsForSelect, setOrderElementsForSelect] = useState([]);
  const [supplierInputValue, setSupplierInputValue] = useState(null);
  const [supplierElementsForSelect, setSupplierElementsForSelect] = useState(
    []
  );
  const [responsible, setResponsible] = useState({});
  const [initialResponsible, setInitialResponsible] = useState([]);
  const [coExecutors, setCoExecutors] = useState({});
  const [coExecutorsFromOrder, setCoExecutorsFromOrder] = useState([]);
  const [imagesForCreate, setImagesForCreate] = useState({});
  const [defUsers, setDefUsers] = useState([]);
  const [attrsForCreate, setAttrsForCreate] = useState(new Set());
  
  useEffect(() => {
    const fetchData = async () => {
      const users = await get_users();
      setDefUsers(users);

      if (orderId) {
        const order = await orderService.get(orderId);
        setOrder(order);
      }
      setAttrsForCreate(attrs_for_create);
      const unitNames = await get_unit_names();
      setUnitNames(unitNames);
    };
    fetchData();
    pushPage(location.pathname);
    const attrs_for_create = new Set(
      roleAccess[userRole].fieldRestrictions.Products?.create
    );
  }, []);

  useEffect(() => {
    setInitialResponsible([user]);
  }, [user]);

  const setOrder = async (order) => {
    const co_executors = [];
    for (const user_id of order.co_executors_ids) {
      const user = await get_user(user_id);
      co_executors.push(user);
    }
    order.co_executors = co_executors;
    setCoExecutorsFromOrder(co_executors);

    setModelData({
      ...modelData,
      order: order,
    });
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      let filters = {};
      if (orderInputValue) filters['id'] = orderInputValue;
      const new_elements = await get_orders(filters);
      setOrderElementsForSelect(new_elements);
    }, 500);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [orderInputValue]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      let filters = {};
      if (supplierInputValue) filters['name'] = supplierInputValue;
      const new_elements = await get_suppliers(filters);
      setSupplierElementsForSelect(new_elements);
    }, 500);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [supplierInputValue]);

  useEffect(() => {
    setModelData({ ...modelData, order_id: orderId });
  }, [orderId]);

  const getDataFromAttr = () => {
    const responsible_ids = Object.values(responsible).map((m) =>
      m ? m.id : null
    );
    if (responsible_ids.includes(null)) {
      setErrors({
        responsible_ids: ['There are blank fields left'],
      });
      return;
    }
  
    const co_executors_ids = Object.values(coExecutors).map((m) =>
      m ? m.id : null
    );
    if (co_executors_ids.includes(null)) {
      setErrors({
        co_executors_ids: ['There are blank fields left'],
      });
      return;
    }
  
    const images = Object.values(imagesForCreate).flat();
    if (images.includes(null)) {
      setErrors({
        images: ['There are blank fields left'],
      });
      return;
    }
  
    const data = {
      ...modelData,
      order_id: modelData.order?.id,
      images: images,
      responsible_ids: responsible_ids,
    };
  
    if (modelData.supplier) {
      data.supplier_id = modelData.supplier.id;
    }
    if (co_executors_ids.length > 0) data.co_executors_ids = co_executors_ids;
    if (!modelData.currency || modelData.currency === '') {
      delete data['currency'];
    }
    delete data['supplier'];
    delete data['order'];
    return data;
  };
  

  if (orderId && !modelData.order) return;
  if (!user) return;
  return (
    <div className="pageCreateModel">
      <div className={styles.saveAndExitBtns}>
        <button
          onClick={async () => {
            const data = getDataFromAttr();
            if (!data) return;
            const result = await create(
              data,
              setErrors,
              setGlobalError,
              resetGlobalMsg,
              language
            );
            if (result) {
              setGlobalMsg(interface_translate['Product created'][language]);
              resetGlobalError();
              setErrors({});
              if (addProduct) {
                addProduct(result);
              }
              if (handleExit) {
                handleExit();
                return;
              }
              navigate('..', { relative: 'path' });
            }
          }}
        >
          {interface_translate['Create'][language]}
        </button>
        <button
          onClick={() => {
            popPage();
            const previousPage = getCurrentPage();
            navigate(previousPage ? previousPage : '..', { relative: 'path' });
          }}
        >
          {interface_translate['Back'][language]}
        </button>
      </div>
      <div className="inputData">
        {attrsForCreate.has('name') && (
          <div className="inputName">
            <div className="openingBlockAttrName">
              {interface_translate['Name'][language]}
            </div>
            <input
              type="text"
              name="name"
              onChange={(e) => {
                setModelData({ ...modelData, name: e.target.value });
              }}
            />
            {errors['name'] && (
              <div className="openingBlockErrorAttr">
                {api_error_translate[errors['name'][0]][language]}
              </div>
            )}
          </div>
        )}
        <OpeningBlock
          title={interface_translate['About product'][language]}
          open={true}
        >
          {attrsForCreate.has('description') && (
            <div>
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Description'][language]}
                </div>
                <input
                  type="text"
                  onChange={(e) => {
                    setModelData({
                      ...modelData,
                      description: e.target.value,
                    });
                  }}
                />
              </div>
              {errors['description'] && (
                <div className="openingBlockErrorAttr">
                  {api_error_translate[errors['description'][0]][language]}
                </div>
              )}
            </div>
          )}
          {attrsForCreate.has('order_id') && (
            <div>
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Order'][language]}
                </div>
                <Autocomplete
                  value={modelData.order}
                  disabled={Boolean(orderId)}
                  onChange={(e, newElement) => {
                    setOrder(newElement);
                  }}
                  options={orderElementsForSelect}
                  getOptionLabel={(model) => model.id.toString()}
                  onInputChange={(e, value) => {
                    setOrderInputValue(value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label={interface_translate['Order'][language]}
                      size="small"
                    />
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  loading={orderElementsForSelect.length == 0}
                />
              </div>
              {errors['order_id'] && (
                <div className="openingBlockErrorAttr">
                  {api_error_translate[errors['order_id'][0]][language]}
                </div>
              )}
            </div>
          )}
          {attrsForCreate.has('link') && (
            <div>
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Link'][language]}
                </div>
                <input
                  type="text"
                  onChange={(e) => {
                    setModelData({
                      ...modelData,
                      link: e.target.value,
                    });
                  }}
                />
              </div>
              {errors['link'] && (
                <div className="openingBlockErrorAttr">
                  {api_error_translate[errors['link'][0]][language]}
                </div>
              )}
            </div>
          )}
          {attrsForCreate.has('qty') && (
            <div>
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Qty'][language]}
                </div>
                <div className={`openingBlockAttrAttrInput horizontal`}>
                  <input
                    type="text"
                    onChange={(e) => {
                      setModelData({
                        ...modelData,
                        qty: e.target.value,
                      });
                    }}
                  />
                  <select
                    value={modelData.measurement_unit}
                    onChange={(e) => {
                      setModelData({
                        ...modelData,
                        measurement_unit: e.target.value,
                      });
                    }}
                  >
                    {unitNames.map((measurement_unit, id) => (
                      <option
                        key={id}
                        value={measurement_unit}
                      >
                        {types[measurement_unit][language]}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              {errors['qty'] && (
                <div className="openingBlockErrorAttr">
                  {api_error_translate[errors['qty'][0]][language]}
                </div>
              )}
            </div>
          )}
          {attrsForCreate.has('price') && (
            <div>
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Price'][language]}
                </div>
                <input
                  type="text"
                  onChange={(e) => {
                    setModelData({
                      ...modelData,
                      price: e.target.value,
                    });
                  }}
                />
              </div>
              {errors['price'] && (
                <div className="openingBlockErrorAttr">
                  {api_error_translate[errors['price'][0].split(":")[0]][language]}
                </div>
              )}
            </div>
          )}
          {attrsForCreate.has('currency') && (
            <div>
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Currency'][language]}
                </div>
                <select
                  onChange={(e) => {
                    setModelData({ ...modelData, currency: e.target.value });
                  }}
                  defaultValue="cny"
                >
                  <option value="rub">RUB</option>
                  <option value="usd">USD</option>
                  <option value="cny">CNY</option>
                </select>
              </div>
              {errors['currency'] && (
                <div className="openingBlockErrorAttr">
                  {api_error_translate[errors['currency'][0]][language]}
                </div>
              )}
            </div>
          )}
          {attrsForCreate.has('supplier_name') && (
            <div>
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Supplier'][language]}
                </div>
                <Autocomplete
                  onChange={(e, newElement) => {
                    setModelData({
                      ...modelData,
                      supplier: newElement,
                    });
                  }}
                  options={supplierElementsForSelect}
                  getOptionLabel={(model) => model.name}
                  onInputChange={(e, value) => {
                    setSupplierInputValue(value);
                    setSupplierElementsForSelect([]);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label={interface_translate['Supplier'][language]}
                      size="small"
                    />
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  loading={supplierElementsForSelect.length == 0}
                />
              </div>
              {errors['supplier_id'] && (
                <div className="openingBlockErrorAttr">
                  {api_error_translate[errors['supplier_id'][0]][language]}
                </div>
              )}
            </div>
          )}
          {attrsForCreate.has('responsible') && (
            <div>
              <ResponsibleListInput
                containerClass={'openingBlockAttr'}
                attrNameClass={'openingBlockAttrName'}
                responsible={responsible}
                setSelectedElement={(value) => {
                  setResponsible((prevData) => {
                    return { ...prevData, ...value };
                  });
                }}
                deleteSelectedElement={(id) => {
                  setResponsible((prevElements) => {
                    delete prevElements[id];
                    return { ...prevElements };
                  });
                }}
                initialElements={initialResponsible}
                defaultElements={defUsers}
              />
              {errors['responsible_ids'] && (
                <div className="openingBlockErrorAttr">
                  {api_error_translate[errors['responsible_ids'][0]][language]}
                </div>
              )}
            </div>
          )}
          {attrsForCreate.has('co_executors') && (
            <div>
              <CoExecutorsInput
                containerClass={'openingBlockAttr'}
                attrNameClass={'openingBlockAttrName'}
                coExecutors={coExecutors}
                setSelectedElement={(value) => {
                  setCoExecutors((prevData) => {
                    return { ...prevData, ...value };
                  });
                }}
                deleteSelectedElement={(id) => {
                  setCoExecutors((prevElements) => {
                    delete prevElements[id];
                    return { ...prevElements };
                  });
                }}
                defaultElements={defUsers}
                initialElements={coExecutorsFromOrder}
              />
              {errors['co_executors_ids'] && (
                <div className="openingBlockErrorAttr">
                  {api_error_translate[errors['co_executors_ids'][0]][language]}
                </div>
              )}
            </div>
          )}
          {attrsForCreate.has('images') && (
            <div>
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Media'][language]}
                  <p>{`${interface_translate['Allowed formats'][language]}: "jpg", "png", "webp"`}</p>
                  <p>{`"heic", "mp4", "mov"`}</p>
                </div>
                <ListAttrMedia
                  setSelectedElement={(value) => {
                    setImagesForCreate((prevData) => ({ ...prevData, ...value }));
                  }}
                  deleteSelectedElement={(id) => {
                    setImagesForCreate((prevData) => {
                      const newData = { ...prevData };
                      delete newData[id];
                      return newData;
                    });
                  }}
                />
              </div>
              {errors['images'] && (
                <div className="openingBlockErrorAttr">
                  {api_error_translate[errors['images'][0]][language]}
                </div>
              )}
            </div>
          )}
        </OpeningBlock>
      </div>
    </div>
  );
};

export default ProductCreatePage;
