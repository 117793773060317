import { useEffect, useState } from 'react';
import { types } from '../../texts/types';
import { api_error_translate } from '../../texts/error';
import { interface_translate } from '../../texts/interface';
import useCommon from '../../hooks/useCommon';
import apiRequester from '../../ApiRequester';
import styles from './shered.module.css';

async function get_shipment_white_logistics_type_transports() {
  const response = await apiRequester.get(
    `/shipments/white_logistics_type_transports`
  );
  return response.data;
}

const WhiteLogisticsCreateForm = (props) => {
  const { language } = useCommon();
  const { data, setData, errors, setErrors } = props;
  const [typeTransports, setTypeTransports] = useState([]);
  useEffect(() => {
    const req = async () => {
      const whiteLogisticsTypeTransports =
        await get_shipment_white_logistics_type_transports();
      setTypeTransports(whiteLogisticsTypeTransports);
    };
    req();
  }, []);

  return (
    <div>
      <div className={styles.blockAttr}>
        <p>{interface_translate['Type of transport'][language]}</p>
        <select
          value={data.type_transport}
          onChange={(e) => {
            setData({ ...data, type_transport: e.target.value });
          }}
        >
          <option value=""></option>
          {typeTransports.map((type, id) => (
            <option
              key={id}
              value={type}
            >
              {types[type][language]}
            </option>
          ))}
        </select>
      </div>
      {errors['type_transport'] && (
        <div className="openingBlockErrorAttr">
          {api_error_translate[errors['type_transport'][0]][language]}
        </div>
      )}
      <div className={styles.blockAttr}>
        <p>{interface_translate['Departure date'][language]}</p>
        <input
          value={data.departure_date}
          onChange={(e) => {
            setData({ ...data, departure_date: e.target.value });
          }}
          type="date"
        />
      </div>
      {errors['departure_date'] && (
        <div className="openingBlockErrorAttr">
          {api_error_translate[errors['departure_date'][0]][language]}
        </div>
      )}
      <div className={styles.blockAttr}>
        <p>
          {interface_translate['Estimated date of arrival in Moscow'][language]}
        </p>
        <input
          value={data.estimated_date_arrival_in_moscow}
          onChange={(e) => {
            setData({
              ...data,
              estimated_date_arrival_in_moscow: e.target.value,
            });
          }}
          type="date"
        />
      </div>
      {errors['estimated_date_arrival_in_moscow'] && (
        <div className="openingBlockErrorAttr">
          {
            api_error_translate[errors['estimated_date_arrival_in_moscow'][0]][
              language
            ]
          }
        </div>
      )}
      <div className={styles.blockAttr}>
        <p>{interface_translate['Total shipping cost'][language]}</p>
        <input
          value={data.shipping_cost}
          onChange={(e) => {
            setData({ ...data, shipping_cost: e.target.value });
          }}
        />
      </div>
      {errors['shipping_cost'] && (
        <div className="openingBlockErrorAttr">
          {api_error_translate[errors['shipping_cost'][0].split(":")[0]][language]}
        </div>
      )}

      <div className={styles.blockAttr}>
        <p>{interface_translate['The cost of the FCS'][language]}</p>
        <input
          value={data.fcs_cost}
          onChange={(e) => {
            setData({ ...data, fcs_cost: e.target.value });
          }}
        />
      </div>
      {errors['fcs_cost'] && (
        <div className="openingBlockErrorAttr">
          {api_error_translate[errors['fcs_cost'][0].split(":")[0]][language]}
        </div>
      )}
      <div className={styles.blockAttr}>
        <p>{interface_translate['Actual total weight in kg'][language]}</p>
        <input
          value={data.actual_gross_weight_in_kg}
          onChange={(e) => {
            setData({ ...data, actual_gross_weight_in_kg: e.target.value });
          }}
        />
      </div>
      {errors['actual_gross_weight_in_kg'] && (
        <div className="openingBlockErrorAttr">
          {api_error_translate[errors['actual_gross_weight_in_kg'][0].split(":")[0]][language]}
        </div>
      )}
      <div className={styles.blockAttr}>
        <p>{interface_translate['Actual total volume in м³'][language]}</p>
        <input
          value={data.actual_gross_volume_in_m3}
          onChange={(e) => {
            setData({ ...data, actual_gross_volume_in_m3: e.target.value });
          }}
        />
      </div>
      {errors['actual_gross_volume_in_m3'] && (
        <div className="openingBlockErrorAttr">
          {api_error_translate[errors['actual_gross_volume_in_m3'][0].split(":")[0]][language]}
        </div>
      )}
      <div className={styles.blockAttr}>
        <p>{interface_translate['Invoice number'][language]}</p>
        <input
          value={data.invoice_number}
          onChange={(e) => {
            setData({ ...data, invoice_number: e.target.value });
          }}
        />
      </div>
      {errors['invoice_number'] && (
        <div className="openingBlockErrorAttr">
          {api_error_translate[errors['invoice_number'][0]][language]}
        </div>
      )}
    </div>
  );
};

export default WhiteLogisticsCreateForm;
