import { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import usePageMovementHistory from '../../hooks/usePageMovementHistory';
import useCommon from '../../hooks/useCommon';
import OpeningBlock from '../../components/OpeningBlock';
import DropDownMediaBlock from '../../components/DropDownMediaBlock';
import ConfirmDialog from '../..//components/ConfirmDialog.jsx';
import ModelView from '../../components/models_view/ModelView.jsx';
import ModalWindow from '../../components/ModalWindow';
import ListAttrMedia from '../../components/ListAttrMedia';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import roleAccess from '../../roleAccess';
import deliveryService from '../../services/delivery.js';
import productService from '../../services/product.js';
import userService from '../../services/user.js';
import orderService from '../../services/order.js';
import storehouseService from '../../services/storehouse.js';
import storageCellService from '../../services/storage_cell.js';
import deliveryProductService from '../../services/delivery_product.js';
import styles from './DeliveryPage.module.css';
import { api_error_translate } from '../../texts/error';
import { statuses_translate } from '../../texts/statuses.js';
import { interface_translate } from '../../texts/interface';
import { types } from '../../texts/types.js';
import {
  admin,
  chinese_storekeeper,
  client_realtions_manager,
  chinese_storekeeper_manager,
  roman_assistant,
} from '../../roles.js';
import '../shared.css';

async function get_delivery(id) {
  const delivery = await deliveryService.get(id);
  return delivery;
}

async function update_delivery(
  id,
  data,
  setErrors,
  setGlobalError,
  resetGlobalMsg,
  language
) {
  const result = await deliveryService.update(
    id,
    data,
    setErrors,
    setGlobalError,
    resetGlobalMsg,
    language
  );
  return result;
}

async function update_delivery_product(
  id,
  data,
  setErrors,
  setGlobalError,
  resetGlobalMsg,
  language
) {
  const result = await deliveryProductService.update(
    id,
    data,
    setErrors,
    setGlobalError,
    resetGlobalMsg,
    language
  );
  return result;
}

async function update_product(
  id,
  data,
  setErrors,
  setGlobalError,
  resetGlobalMsg,
  language
) {
  const result = await productService.update(
    id,
    data,
    setErrors,
    setGlobalError,
    resetGlobalMsg,
    language
  );
  return result;
}

async function get_user(id) {
  const result = await userService.get(id);
  return result;
}

async function get_order(id) {
  const order = await orderService.get(id);
  return order;
}

async function get_storehouses(filters = {}, size = 50) {
  const storehouses_paginated = await storehouseService.get_by_filters(
    filters,
    { size: size }
  );
  return storehouses_paginated.data;
}

async function get_storage_cells(filters = {}, size = 50) {
  const storage_cells_paginated = await storageCellService.get_by_filters(
    filters,
    { size: size }
  );
  return storage_cells_paginated.data;
}

async function product_delivery_check(
  product_id,
  data,
  setErrors,
  resetGlobalMsg
) {
  const result = await deliveryProductService.check(
    product_id,
    data,
    setErrors,
    resetGlobalMsg
  );
  return result;
}

function get_delivery_product_for_display(
  delivery_product,
  order,
  userRole,
  language,
  setViewProductAddInformationModal,
  setProductAddInformationData,
  setCoordinationValuationForProducts
) {
  const product = delivery_product.product;
  if (!product.link) product.link = '';
  if (
    [admin, chinese_storekeeper, chinese_storekeeper_manager].includes(
      userRole
    ) &&
    delivery_product.status == 'received_in_chine_storehouse'
  ) {
    delivery_product.add_information = (
      <button
        onClick={(e) => {
          e.stopPropagation();
          setViewProductAddInformationModal(true);
          setProductAddInformationData({
            delivery_product_id: delivery_product.id,
            qty: delivery_product.qty,
            was_qty_check: false,
          });
        }}
      >
        {interface_translate['Add information'][language]}
      </button>
    );
  }
  delivery_product.qty_defective_products = (
    <input
      defaultValue={0}
      onClick={(e) => {
        e.stopPropagation();
      }}
      onChange={(e) => {
        setCoordinationValuationForProducts((prevData) => {
          return {
            ...prevData,
            [delivery_product.id]: {
              ...prevData[delivery_product.id],
              qty_defective_products: e.target.value,
            },
          };
        });
      }}
    />
  );

  product.qty_with_unit = `${product.qty} ${
    types[product.measurement_unit][language]
  }`;
  product.total_cost = product.price * product.qty;
  product.supplier_name = product.supplier?.name;
  product.client_name = order.client.name;

  delivery_product.delivery_qty_with_unit = `${delivery_product.qty} ${
    types[delivery_product.product.measurement_unit][language]
  }`;
  delivery_product.status_translate =
    statuses_translate[delivery_product.status][language];
  delivery_product.storehouse_id = delivery_product.storage_cell
    ? delivery_product.storage_cell.shelving.storehouse.id
    : '';
  delivery_product.storehouse_name = delivery_product.storage_cell
    ? delivery_product.storage_cell.shelving.storehouse.name
    : '';
  delivery_product.storage_cell_name = delivery_product.storage_cell
    ? `${delivery_product.storage_cell.shelving.name}${delivery_product.storage_cell.number}`
    : '';

  return { ...product, ...delivery_product };
}

const product_table_all_attrs = [
  {
    name: 'id',
    name_for_display: 'product id',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'client_name',
    name_for_display: 'client',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'order_id',
    name_for_display: 'order',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'name',
    name_for_display: 'name',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'delivery_qty_with_unit',
    name_for_display: 'qty',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'status_translate',
    name_for_display: 'status',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'price',
    name_for_display: 'price',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'currency',
    name_for_display: 'currency',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'storehouse_name',
    name_for_display: 'storehouse',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'storage_cell_name',
    name_for_display: 'storage cell',
    translate_name_for_display: null,
    use_sort: true,
  },
  {
    name: 'supplier_name',
    name_for_display: 'supplier',
    translate_name_for_display: null,
    use_sort: true,
  },
];

const DeliveryPage = (props) => {
  const {
    language,
    setGlobalMsg,
    resetGlobalMsg,
    setGlobalError,
    resetGlobalError,
    userRole,
  } = useCommon();
  const { handleExit } = props;
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { pushPage, popPage, getCurrentPage } = usePageMovementHistory();
  const [delivery, setDelivery] = useState();
  const [storehouse, setStorehouse] = useState(null);
  const [storehouseInputValue, setStorehouseInputValue] = useState('');
  const [storehouseElementsForSelect, setStorehouseElementsForSelect] =
    useState([]);
  const [storageCellBlocksInputValue, setStorageCellBlocksInputValue] =
    useState({});
  const [
    storageCellBlocksElementsForSelect,
    setStorageCellBlocksElementsForSelect,
  ] = useState({});
  const [storageCellBlockIdInFocus, setStorageCellBlockIdInFocus] =
    useState(null);
  const [viewCoordinationWithClient, setViewCoordinationWithClient] =
    useState(false);
  const [
    coordinationValuationForProducts,
    setCoordinationValuationForProducts,
  ] = useState({});
  const [viewSelectedStorageCells, setViewSelectedStorageCells] =
    useState(false);
  const [selectedStorageCells, setSelectedStorageCells] = useState();
  const [countProductForDeliveryCheck, setCountProductForDeliveryCheck] =
    useState(0);
  const [viewProductAddInformationModal, setViewProductAddInformationModal] =
    useState(false);
  const [productAddInformationData, setProductAddInformationData] = useState(
    {}
  );
  const [productAddInformationErrors, setProductAddInformationErrors] =
    useState({});
  const [errors, setErrors] = useState({});
  const [attrsForRead, setAttrsForRead] = useState(new Set());
  const [productTableAttrs, setProductTableAttrs] = useState([]);

  useEffect(() => {
    const req = async () => {
      await requestGetDelivery();
    };
    req();
    pushPage(location.pathname);
    const attrs_for_read = new Set(
      roleAccess[userRole].fieldRestrictions.Deliveries?.read
    );
    setAttrsForRead(attrs_for_read);
    const product_table_attrs = product_table_all_attrs
      .filter((attr_info) =>
        roleAccess[userRole].fieldRestrictions.Products?.read_all.includes(
          attr_info.name
        )
      )
      .map((attr_info) => ({
        ...attr_info,
        translate_name_for_display:
          interface_translate[attr_info.name_for_display][language],
      }));
    setProductTableAttrs(product_table_attrs);
  }, []);

  useEffect(() => {
    if (!delivery) return;
    delivery.status_tranclate = statuses_translate[delivery.status][language];
    setDelivery({ ...delivery });
  }, [language]);

  const getDataFromProductAddInformationData = () => {
    if (!productAddInformationData.hasOwnProperty('media_content')) {
      productAddInformationData.media_content = [];
    }
    const data = {
      check_weight_in_kg: productAddInformationData.weight_in_kg,
      check_volume_in_m3: productAddInformationData.volume_in_m3,
      check_content: productAddInformationData.content,
      check_qty: productAddInformationData.qty,
      was_qty_check: productAddInformationData.was_qty_check,
      media_content: Object.values(productAddInformationData.media_content).flat()
    };
    return data;
  };

  useEffect(() => {
    if (countProductForDeliveryCheck == 0) {
      setProductTableAttrs((prevData) => {
        prevData.pop();
        return [...prevData];
      });
    }
  }, [countProductForDeliveryCheck]);

  const saveCoordinationValuation = async () => {
    const uncoordinatedProducts = delivery.delivery_products.filter(
      product => !coordinationValuationForProducts[product.id]
    );
  
    if (uncoordinatedProducts.length > 0) {
      setGlobalError(
        `${interface_translate['Confirm or reject products'][language]}: ${
          uncoordinatedProducts.map(p => p.id).join(', ')
        }`
      );
      return;
    }
  
    for (const delivery_product of delivery.delivery_products) {
      let status = '';
      if (
        coordinationValuationForProducts[delivery_product.id].valuation === true
      ) {
        status = 'checked';
      } else if (
        coordinationValuationForProducts[delivery_product.id].valuation ===
        false
      ) {
        status = 'return_to_supplier';
      }
      const product = delivery_product.product;
      let qty_defective_products = coordinationValuationForProducts[
        delivery_product.id
      ].qty_defective_products
        ? coordinationValuationForProducts[delivery_product.id]
            .qty_defective_products
        : 0;
      qty_defective_products = qty_defective_products
        ? qty_defective_products
        : 0;
      let new_qty_products_in_china = product.qty_products_in_china;
      if (status === 'checked') {
        new_qty_products_in_china =
          product.qty_products_in_china - qty_defective_products;
      } else if (status === 'return_to_supplier') {
        new_qty_products_in_china =
          product.qty_products_in_china - delivery_product.qty;
      }
      await update_delivery_product(
        delivery_product.id,
        { status: status },
        setErrors,
        setGlobalError,
        resetGlobalMsg,
        language
      );
      await update_product(
        product.id,
        {
          qty_products_in_china: new_qty_products_in_china,
        },
        setErrors,
        setGlobalError,
        resetGlobalMsg,
        language
      );
    }
    for (const delivery_product of delivery.delivery_products) {
      if (
        coordinationValuationForProducts[delivery_product.id].valuation === true
      ) {
        delivery_product.status = 'checked';
      } else if (
        coordinationValuationForProducts[delivery_product.id].valuation ===
        false
      ) {
        delivery_product.status = 'return_to_supplier';
      }
      delivery_product.status_translate =
        statuses_translate[delivery_product.status][language];
    }
    delivery.products_is_agreed = true;
    update_delivery(
      delivery.id,
      { products_is_agreed: true },
      setErrors,
      setGlobalError,
      resetGlobalMsg,
      language
    );
    setDelivery({ ...delivery });
    onClickCoordinationWithClient();
  };

  useEffect(() => {
    if (!delivery) return;
    for (const delivery_product of delivery.delivery_products) {
      delivery_product.сoordination = (
        <div
          className={styles.coordinationeValuationBtns}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <button
            className={
              coordinationValuationForProducts[delivery_product.id]
                ?.valuation === true
                ? styles.active
                : ''
            }
            onClick={(у) => {
              setCoordinationValuationForProducts({
                ...coordinationValuationForProducts,
                [delivery_product.id]: {
                  ...coordinationValuationForProducts[delivery_product.id],
                  valuation: true,
                },
              });
            }}
          >
            ✅
          </button>
          <button
            className={
              coordinationValuationForProducts[delivery_product.id]
                ?.valuation === false
                ? styles.active
                : ''
            }
            onClick={(у) => {
              setCoordinationValuationForProducts({
                ...coordinationValuationForProducts,
                [delivery_product.id]: {
                  ...coordinationValuationForProducts[delivery_product.id],
                  valuation: false,
                },
              });
            }}
          >
            🚫
          </button>
        </div>
      );
    }
    setDelivery({ ...delivery });
  }, [coordinationValuationForProducts]);

  const onClickCoordinationWithClient = (e) => {
    const newViewCoordinationWithClient = !viewCoordinationWithClient;
    if (newViewCoordinationWithClient) {
      setCoordinationValuationForProducts({});
      setProductTableAttrs((prevData) => {
        return [
          ...prevData,
          {
            name: 'сoordination',
            name_for_display: '',
            translate_name_for_display: '',
            use_sort: false,
          },
          {
            name: 'qty_defective_products',
            name_for_display: 'qty defective products',
            translate_name_for_display:
              interface_translate['qty defective products'][language],
            use_sort: false,
          },
        ];
      });
    } else {
      for (const delivery_produc of delivery.delivery_products) {
        delivery_produc.сoordination = null;
      }
      setProductTableAttrs((prevData) => {
        prevData.pop();
        prevData.pop();
        return [...prevData];
      });
    }
    setViewCoordinationWithClient(newViewCoordinationWithClient);
    setDelivery({ ...delivery });
  };

  const saveSelectedStorageCells = async () => {
    let delivery_product_id_delivery_product = {};
    for (const delivery_product of delivery.delivery_products) {
      delivery_product_id_delivery_product[delivery_product.id] =
        delivery_product;
    }
    for (const delivery_product_id of Object.keys(selectedStorageCells)) {
      const storage_cell = selectedStorageCells[delivery_product_id];
      delivery_product_id_delivery_product[delivery_product_id].storage_cell =
        storage_cell;
      delivery_product_id_delivery_product[delivery_product_id] =
        get_delivery_product_for_display(
          delivery_product_id_delivery_product[delivery_product_id],
          delivery.order,
          userRole,
          language,
          setViewProductAddInformationModal,
          setProductAddInformationData,
          setCoordinationValuationForProducts
        );
        await update_delivery_product(
        delivery_product_id,
        {
          storage_cell_id: storage_cell.id,
        },
        setErrors,
        setGlobalError,
        resetGlobalMsg,
        language
      );
    }
    for (const delivery_product of delivery.delivery_products) {
      delivery_product.storage_cell = selectedStorageCells[delivery_product.id];
    }
    delivery.for_products_selected_storage_cells = true;
    await update_delivery(delivery.id, { for_products_selected_storage_cells: true });
    setDelivery({ ...delivery });
    onClickSelectedStorageCells();
    // setProductTableAttrs((prevData) => {
    //   return [
    //     ...prevData,
    //     {
    //       name: 'add_information',
    //       name_for_display: '',
    //       translate_name_for_display: '',
    //       use_sort: false,
    //     },
    //   ];
    // });
    await requestGetDelivery();
  };

  useEffect(() => {
    if (!delivery) return;
    for (const delivery_product of delivery.delivery_products) {
      delivery_product.select_storage_cell = (
        <div
          className={styles.blockSelectStorehouse}
          onClick={(e) => e.stopPropagation()}
        >
          <Autocomplete
            value={selectedStorageCells?.[delivery_product.id]}
            onChange={(e, newElement) => {
              setSelectedStorageCells({
                ...selectedStorageCells,
                [delivery_product.id]: newElement,
              });
            }}
            options={
              storageCellBlocksElementsForSelect[delivery_product.id]
                ? storageCellBlocksElementsForSelect[delivery_product.id]
                : []
            }
            getOptionLabel={(model) => `${model.shelving.name}${model.number}`}
            onInputChange={(e, value) => {
              setStorageCellBlocksInputValue((prevInputValue) => ({
                ...prevInputValue,
                [delivery_product.id]: value,
              }));
              setStorageCellBlockIdInFocus(delivery_product.id);
              setStorageCellBlocksElementsForSelect((prevData) => {
                const new_data = { ...prevData, [delivery_product.id]: [] };
                return new_data;
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label={interface_translate['Storage cell'][language]}
                size="small"
              />
            )}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
        </div>
      );
    }
    setDelivery({ ...delivery });
  }, [selectedStorageCells, storageCellBlocksElementsForSelect]);

  const onClickSelectedStorageCells = (e) => {
    const newViewSelectedStorageCells = !viewSelectedStorageCells;
    if (newViewSelectedStorageCells) {
      setSelectedStorageCells({});
      setProductTableAttrs((prevData) => {
        return [
          ...prevData,
          {
            name: 'select_storage_cell',
            name_for_display: '',
            translate_name_for_display: '',
            use_sort: false,
          },
        ];
      });
    } else {
      for (const delivery_product of delivery.delivery_products) {
        delivery_product.select_storage_cell = null;
      }
      setProductTableAttrs((prevData) => {
        prevData.pop();
        return [...prevData];
      });
    }
    setViewSelectedStorageCells(newViewSelectedStorageCells);
    setDelivery({ ...delivery });
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      let filters = {};
      if (storehouseInputValue) filters['name'] = storehouseInputValue;
      const new_elements = await get_storehouses(filters);
      setStorehouseElementsForSelect(new_elements);
    }, 500);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [storehouseInputValue]);

  useEffect(() => {
    if (!storageCellBlockIdInFocus) return;
    const delayDebounceFn = setTimeout(async () => {
      const new_elements = await get_storage_cells({
        storage_cell_name:
          storageCellBlocksInputValue[storageCellBlockIdInFocus],
      });
      setStorageCellBlocksElementsForSelect((prevElements) => ({
        ...prevElements,
        [storageCellBlockIdInFocus]: [...new_elements],
      }));
    }, 500);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [storageCellBlockIdInFocus, storageCellBlocksInputValue]);

  const requestGetDelivery = async () => {
    const delivery_resp = await get_delivery(params.id);
    let order = await get_order(delivery_resp.order_id);
    delivery_resp.order = order;
    const responsible = [];
    for (const user_id of delivery_resp.responsible_ids) {
      const user = await get_user(user_id);
      responsible.push(user);
    }
    delivery_resp.responsible = responsible;
    const co_executors = [];
    for (const user_id of delivery_resp.co_executors_ids) {
      const user = await get_user(user_id);
      co_executors.push(user);
    }
    delivery_resp.co_executors = co_executors;
    delivery_resp.status_tranclate =
      statuses_translate[delivery_resp.status][language];
    let countProductForDeliveryCheck_ = 0;
    let total_amount = 0;
    let delivery_products = [];
    for (let delivery_product of delivery_resp.delivery_products) {
      if (delivery_product.status == 'received_in_chine_storehouse') {
        countProductForDeliveryCheck_++;
      }
      delivery_product = get_delivery_product_for_display(
        delivery_product,
        order,
        userRole,
        language,
        setViewProductAddInformationModal,
        setProductAddInformationData,
        setCoordinationValuationForProducts
      );
      if (delivery_product.currency == 'cny') {
        total_amount += delivery_product.total_cost * order.rate_cny_to_rub;
      } else if (delivery_product.currency == 'usd') {
        total_amount += delivery_product.total_cost * order.rate_dollar_to_rub;
      } else if (delivery_product.currency == 'rub') {
        total_amount += delivery_product.total_cost;
      }
      delivery_products.push(delivery_product);
    }
    delivery_resp.delivery_products = delivery_products;
    if (
      [admin, chinese_storekeeper, chinese_storekeeper_manager].includes(
        userRole
      ) &&
      countProductForDeliveryCheck == 0 &&
      countProductForDeliveryCheck_ > 0 &&
      delivery_resp.for_products_selected_storage_cells
    ) {
      setProductTableAttrs((prevData) => {
        return [
          ...prevData,
          {
            name: 'add_information',
            name_for_display: '',
            translate_name_for_display: '',
            use_sort: false,
          },
        ];
      });
      setCountProductForDeliveryCheck(countProductForDeliveryCheck_);
    }
    delivery_resp.total_amount = total_amount;
    setDelivery(delivery_resp);
  };

  if (delivery == null) return;

  return (
    <div className="pageViewModel">
      <div className="controls">
        {roleAccess[userRole].permissions.Deliveries.edit && (
          <button
            onClick={() => {
              navigate('edit');
            }}
          >
            {interface_translate['Edit'][language]}
          </button>
        )}
        <button
          onClick={() => {
            popPage();
            const previousPage = getCurrentPage();
            navigate(previousPage ? previousPage : '..', { relative: 'path' });
          }}
        >
          {interface_translate['Back'][language]}
        </button>
        <button
          onClick={() => {
            navigate('..', { relative: 'path' });
          }}
        >
          {interface_translate['Exit'][language]}
        </button>
      </div>
      {delivery && (
        <div className="viewData">
          <OpeningBlock
            title={interface_translate['About delivery'][language]}
            open={true}
          >
            {attrsForRead.has('id') && (
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['delivery id'][language]}
                </div>
                <span>{delivery.id}</span>
              </div>
            )}
            {attrsForRead.has('order_id') && (
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Order'][language]}
                </div>
                <span
                  className="linkToModel"
                  onClick={() =>
                    navigate(`/orders/${delivery.order_id}`, {
                      state: { from: location },
                    })
                  }
                >
                  {delivery.order_id}
                </span>
              </div>
            )}
            {attrsForRead.has('client_name') && (
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Client'][language]}
                </div>
                <span
                  className="linkToModel"
                  onClick={() =>
                    navigate(`/counterparties/${delivery.order.client.id}`, {
                      state: { from: location },
                    })
                  }
                >
                  {delivery.order.client.name}
                </span>
              </div>
            )}
            {attrsForRead.has('track_number') && (
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Track number'][language]}
                </div>
                <span>{delivery.track_number}</span>
              </div>
            )}
            {attrsForRead.has('status_tranclate') && (
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Status'][language]}
                </div>
                <span>{delivery.status_tranclate}</span>
              </div>
            )}
            {attrsForRead.has('total_amount') && (
              <div>
                <div className="openingBlockAttr">
                  <div className="openingBlockAttrName">
                    {interface_translate['Total amount ₽'][language]}
                  </div>
                  <span>{delivery.total_amount}</span>
                </div>
              </div>
            )}
            {attrsForRead.has('media_content_acceptance') && (
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Media content'][language]}
                </div>
                <div className="listDropDomnMedia">
                  {delivery.media_content_acceptance.map((model, id) => {
                    let mediaBlock;
                    let preViewImg;
                    if (model.file_type === 'photo') {
                      mediaBlock = (
                        <img
                          src={model.url}
                          alt="image"
                        />
                      );
                      preViewImg = mediaBlock;
                    } else if (model.file_type === 'video') {
                      mediaBlock = (
                        <video
                          src={model.url}
                          alt="video"
                          controls
                          style={{ maxWidth: '100%' }}
                        />
                      );
                      preViewImg = (
                        <p>{interface_translate['Video'][language]}</p>
                      );
                    }
                    return (
                      <DropDownMediaBlock
                        key={id}
                        preViewImg={preViewImg}
                        mediaBlock={mediaBlock}
                      />
                    );
                  })}
                </div>
              </div>
            )}
            {attrsForRead.has('responsible') && (
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Responsible'][language]}
                </div>
                <div className="listModels">
                  {delivery.responsible.map((model) => {
                    return (
                      <div
                        className="modelBlock"
                        key={model.id}
                      >
                        {`${model.surname} ${model.name} ${model.patronymic}`}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {attrsForRead.has('co_executors') && (
              <div className="openingBlockAttr">
                <div className="openingBlockAttrName">
                  {interface_translate['Co executors'][language]}
                </div>
                <div className="listModels">
                  {delivery.co_executors.map((model) => {
                    return (
                      <div
                        className="modelBlock"
                        key={model.id}
                      >
                        {`${model.surname} ${model.name} ${model.patronymic}`}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </OpeningBlock>
        </div>
      )}
      <div>
        <div className="openingBlockAttrName">
          {interface_translate['Products'][language]}
        </div>
        <div className="relatedModelComtrols">
          {[client_realtions_manager, admin, roman_assistant].includes(
            userRole
          ) &&
            delivery.products_is_agreed == false &&
            delivery.delivery_products.filter(
              (m) => m.status == 'awaiting_approval'
            ).length == delivery.delivery_products.length && (
              <button onClick={onClickCoordinationWithClient}>
                {interface_translate['Coordination with the client'][language]}
              </button>
            )}
          {viewCoordinationWithClient && (
            <button
              onClick={(e) => {
                saveCoordinationValuation();
              }}
            >
              {interface_translate['Save'][language]}
            </button>
          )}
        </div>
        <div className="relatedModelComtrols">
          {[chinese_storekeeper, chinese_storekeeper_manager, admin].includes(
            userRole
          ) &&
            delivery.for_products_selected_storage_cells == false &&
            (delivery.status == 'product_is_checked' ||
              delivery.status == 'product_received_in_china_storehouse') && (
              <button onClick={onClickSelectedStorageCells}>
                {
                  interface_translate['For products selected storage cells'][
                    language
                  ]
                }
              </button>
            )}
          {viewSelectedStorageCells && (
            <button
              onClick={(e) => {
                saveSelectedStorageCells();
              }}
            >
              {interface_translate['Save'][language]}
            </button>
          )}
          {viewSelectedStorageCells && (
            <div className={styles.blockSelectStorehouse}>
              <Autocomplete
                value={storehouse}
                onChange={async (e, newElement) => {
                  setStorehouse(newElement);
                  const filters = { storehouse_id: newElement.id };
                  const new_storage_cells = await get_storage_cells(filters);
                  const newStorageCellBlocksElementsForSelect = {};
                  for (const delivery_product of delivery.delivery_products) {
                    newStorageCellBlocksElementsForSelect[delivery_product.id] =
                      new_storage_cells;
                  }
                  setStorageCellBlocksElementsForSelect(
                    newStorageCellBlocksElementsForSelect
                  );
                  setSelectedStorageCells({});
                }}
                options={storehouseElementsForSelect}
                getOptionLabel={(model) => model.name}
                onInputChange={(e, value) => {
                  setStorehouseInputValue(value);
                  setStorehouseElementsForSelect([]);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label={interface_translate['Storehouse'][language]}
                    size="small"
                  />
                )}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                loading={storehouseElementsForSelect.length == 0}
              />
            </div>
          )}
        </div>
        <ModelView
          tableAttrs={productTableAttrs}
          models={delivery.delivery_products}
          useSelected={false}
          usePagination={false}
          useSettings={false}
          handleModelClick={(model) =>
            navigate(`/products/${model.product.id}`, {
              state: { from: location },
            })
          }
        />
        {viewProductAddInformationModal && (
          <ModalWindow
            isActive={viewProductAddInformationModal}
            setIsActive={(v) => {
              setProductAddInformationData({});
              setViewProductAddInformationModal(v);
            }}
          >
            <div
              className={`modalWindowDefaultContainer ${styles.productAddInformationContainer}`}
            >
              <div className={styles.controlBtns}>
                <button
                  onClick={async () => {
                    const data = getDataFromProductAddInformationData();
                    if (!data) return;
                    const result = await product_delivery_check(
                      productAddInformationData.delivery_product_id,
                      data,
                      setProductAddInformationErrors,
                      resetGlobalMsg
                    );
                    if (result) {
                      setGlobalMsg(interface_translate['Data added'][language]);
                      resetGlobalError();
                      setErrors({});
                      setCountProductForDeliveryCheck((prevData) => {
                        return prevData - 1;
                      });
                      requestGetDelivery();
                      setViewProductAddInformationModal(false);
                      setProductAddInformationData({});
                    }
                  }}
                >
                  {interface_translate['Save'][language]}
                </button>
                <button
                  onClick={() => {
                    setProductAddInformationData({});
                    setViewProductAddInformationModal(false);
                  }}
                >
                  {interface_translate['Exit'][language]}
                </button>
              </div>
              <div className="openingBlockAttr">
                <p className="openingBlockAttrName">
                  {interface_translate['Weight in kg'][language]}
                </p>
                <input
                  type="text"
                  value={productAddInformationData.weight_in_kg}
                  onChange={(e) => {
                    setProductAddInformationData({
                      ...productAddInformationData,
                      weight_in_kg: e.target.value,
                    });
                  }}
                />
              </div>
              {productAddInformationErrors['check_weight_in_kg'] && (
                <div className="openingBlockErrorAttr">
                  {
                    api_error_translate[
                      productAddInformationErrors['check_weight_in_kg'][0]
                    ][language]
                  }
                </div>
              )}
              <div className="openingBlockAttr">
                <p className="openingBlockAttrName">
                  {interface_translate['Volume in м³'][language]}
                </p>
                <input
                  type="text"
                  value={productAddInformationData.volume_in_m3}
                  onChange={(e) => {
                    setProductAddInformationData({
                      ...productAddInformationData,
                      volume_in_m3: e.target.value,
                    });
                  }}
                />
              </div>
              {productAddInformationErrors['check_volume_in_m3'] && (
                <div className="openingBlockErrorAttr">
                  {
                    api_error_translate[
                      productAddInformationErrors['check_volume_in_m3'][0]
                    ][language]
                  }
                </div>
              )}
              <div className="openingBlockAttr">
                <p className="openingBlockAttrName">
                  {interface_translate['Content'][language]}
                </p>
                <input
                  type="text"
                  value={productAddInformationData.сontent}
                  onChange={(e) => {
                    setProductAddInformationData({
                      ...productAddInformationData,
                      content: e.target.value,
                    });
                  }}
                />
              </div>
              {productAddInformationErrors['check_content'] && (
                <div className="openingBlockErrorAttr">
                  {
                    api_error_translate[
                      productAddInformationErrors['check_content'][0]
                    ][language]
                  }
                </div>
              )}
              <div className="openingBlockAttr">
                <p className="openingBlockAttrName">
                  {interface_translate['Qty'][language]}
                </p>
                <input
                  type="text"
                  value={productAddInformationData.qty}
                  onChange={(e) => {
                    setProductAddInformationData({
                      ...productAddInformationData,
                      qty: e.target.value,
                    });
                  }}
                />
              </div>
              {productAddInformationErrors['check_qty'] && (
                <div className="openingBlockErrorAttr">
                  {
                    api_error_translate[
                      productAddInformationErrors['check_qty'][0]
                    ][language]
                  }
                </div>
              )}
              <div className="openingBlockAttr">
                <p className="openingBlockAttrName">
                  {interface_translate['Was check qty'][language]}
                </p>
                <input
                  type="checkbox"
                  value="was_qty_check"
                  onChange={(e) => {
                    setProductAddInformationData({
                      ...productAddInformationData,
                      was_qty_check: e.target.checked,
                    });
                  }}
                />
              </div>
              <div className="openingBlockAttr">
                <p className="openingBlockAttrName">
                  {interface_translate['Media content'][language]}
                  <br />
                  {`${interface_translate['Allowed formats'][language]}: "jpg", "png", "webp"`}
                  {`"heic", "mp4", "mov", "pdf", "docx", "xlsx"`}
                </p>
                <ListAttrMedia
                  setSelectedElement={(value) => {
                    setProductAddInformationData({
                      ...productAddInformationData,
                      media_content: {
                        ...productAddInformationData.media_content,
                        ...value,
                      },
                    });
                  }}
                  deleteSelectedElement={(id) => {
                    const old_media_content =
                      productAddInformationData.media_content;
                    delete old_media_content[id];
                    setProductAddInformationData({
                      ...productAddInformationData,
                      media_content: old_media_content,
                    });
                  }}
                />
              </div>
              {productAddInformationErrors['check_media_content'] && (
                <div className="openingBlockErrorAttr">
                  {
                    api_error_translate[
                      productAddInformationErrors['check_media_content'][0]
                    ][language]
                  }
                </div>
              )}
            </div>
          </ModalWindow>
        )}
      </div>
      {!delivery && <div>Пусто</div>}
    </div>
  );
};

export default DeliveryPage;
